import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../../components';
import {
  determineConfirmButtonColour,
  determineConfirmButtonLabel,
  determineModalSubHeading,
} from './utils/utils';

export interface SaveCustomContentModalProps {
  clearTopologyToSave: VoidFunction;
  clearTopologyToUpdate: VoidFunction;
  isSaving: boolean;
  saveTopology: (topologyUid: string) => null;
  updateDemo: (demo?: DemosUpdatePayload) => null;
  topologyToSave: Topology;
  topologyToUpdate: Topology;
  isFormDirty: boolean;
}

export function SaveCustomContentModal({
  clearTopologyToSave,
  clearTopologyToUpdate,
  isSaving,
  saveTopology,
  updateDemo,
  topologyToSave,
  topologyToUpdate,
  isFormDirty,
}: SaveCustomContentModalProps): ReactElement | null {
  const { t } = useTranslation();
  const topology = topologyToSave ? topologyToSave : topologyToUpdate;

  if (!topology) {
    return null;
  }

  function handleConfirm() {
    if (topologyToSave) {
      saveTopology(topology.uid);
      return;
    }
    updateDemo({ id: topology.demoId!, topology: { uid: topology.uid } });
  }

  return (
    <ConfirmModal
      heading={t(`topologies.updatePush.modalHeading`)}
      subHeading={determineModalSubHeading({
        isFormDirty,
        topologyName: topology.name,
      })}
      loading={isSaving}
      onCancel={
        topologyToSave
          ? () => clearTopologyToSave()
          : () => clearTopologyToUpdate()
      }
      onClose={
        topologyToSave
          ? () => clearTopologyToSave()
          : () => clearTopologyToUpdate()
      }
      onConfirm={handleConfirm}
      confirmButtonLabel={determineConfirmButtonLabel({
        isFormDirty,
        topologyToSave,
      })}
      confirmButtonColour={determineConfirmButtonColour({ isFormDirty })}
      show={!!topology}
      size="small"
      testId="topology-save-modal"
    />
  );
}
