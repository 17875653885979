import React, { ReactElement } from 'react';
import { ConnectedCloneTopologyModal } from '../../../../../pages/Topologies/components/CloneTopologyModal/container';
import { ClonedTopologyLinkModalContainer } from '../../../../../pages/Topologies/components/ClonedTopologyLinkModal/container';
import { CloneTopologyButtonContainer } from './components/CloneTopologyButton/container';

export const Clone = (): ReactElement => {
  return (
    <>
      <CloneTopologyButtonContainer />
      <ConnectedCloneTopologyModal />
      <ClonedTopologyLinkModalContainer />
    </>
  );
};
