import i18n from '../../../../../../../../i18n';

type DetermineScheduleModalSubHeading = {
  isFormDirty: boolean;
};

export const determineScheduleModalSubHeading = ({
  isFormDirty,
}: DetermineScheduleModalSubHeading): string =>
  isFormDirty
    ? i18n.t('topologies.schedule.modalSubheadingWithWarning')
    : i18n.t('topologies.schedule.inSync.subHeading');

type DetermineScheduleModalConfirmButtonLabel = {
  isFormDirty: boolean;
};

export const determineScheduleModalConfirmButtonLabel = ({
  isFormDirty,
}: DetermineScheduleModalConfirmButtonLabel): string => {
  return isFormDirty
    ? i18n.t('buttons.scheduleWithoutChanges')
    : i18n.t('topologies.schedule.inSync.confirmationButtonLabel');
};
