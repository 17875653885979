import { SelectOption } from 'dcloud-shared-ui';

export const buildEvcModeOptions = (
  evcModes: EvcMode[],
): SelectOption<string>[] =>
  [...evcModes]
    ?.sort((evcModeA, evcModeB) =>
      evcModeA.displayOrder > evcModeB.displayOrder ? 1 : -1,
    )
    ?.map((evcMode) => ({ name: evcMode.name, value: evcMode.id }));
