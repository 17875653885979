import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../../Tooltip';
import styles from './index.module.css';

export const PushSpinner = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div>
      <Tooltip
        position="down"
        id="pushFromHeaderTooltipSpinner"
        text={`${t('common.pleaseWait')} ...`}
        size="small"
      >
        <Icon name="spinner" className={styles.spinner} />
      </Tooltip>
    </div>
  );
};
