import { Links, NavLinks, ThemeSwitch } from 'dcloud-shared-ui';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MainRoutes } from '../../../constants/routes';
import UserPreferencesLink from '../components/UserPreferences/UserPreferencesLink';
import ConnectedUserPreferencesModal from '../components/UserPreferences/container';
import { Header } from '../../Header';

export function SiteHeader(): ReactElement {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);

  const links = useMemo<Links[]>(
    () => [
      {
        active: pathname === MainRoutes.Main,
        label: t('common.home'),
        to: MainRoutes.Main,
      },
      {
        active:
          pathname === MainRoutes.Topologies ||
          pathname.includes(MainRoutes.CreateTopology),
        label: t('common.topologies'),
        to: MainRoutes.Topologies,
      },
    ],
    [pathname, t],
  );

  const openSettingsModal = (event: { preventDefault: VoidFunction }) => {
    event.preventDefault();
    setSettingsOpen(true);
  };
  return (
    <Header
      title={t('siteTitle')}
      right={
        <>
          <NavLinks links={links} />
          <UserPreferencesLink onClick={openSettingsModal} />
          <ThemeSwitch />
          {isSettingsOpen && (
            <ConnectedUserPreferencesModal
              onClose={() => setSettingsOpen(false)}
            />
          )}
        </>
      }
      logoLinkTo={MainRoutes.Main}
    />
  );
}
