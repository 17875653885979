import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stripGzipFromEtag } from '../../../api/utils';
import { RootState } from '../../store';

export interface etagCollectionState {
  etags: CustomEtag[];
}

export const initialState: etagCollectionState = {
  etags: [],
};

const slice = createSlice({
  initialState,
  name: 'etagCollections',
  reducers: {
    addCustomEtag: (state, action: PayloadAction<CustomEtag>) => {
      state.etags = [
        ...state.etags.filter(
          (etagData) => etagData.referenceName !== action.payload.referenceName,
        ),
        {
          etag: stripGzipFromEtag(action.payload.etag),
          referenceName: action.payload.referenceName,
        },
      ];
    },
  },
});

export const getEtagCollectionsState = (
  state: RootState,
): etagCollectionState => state[slice.name];

export const customEtagSelectors = {
  getAllCustomEtags: (state: RootState): CustomEtag[] =>
    getEtagCollectionsState(state).etags,
  getCustomEtag: createSelector(
    getEtagCollectionsState,
    (_: RootState, args: { url: string }) => args.url,
    (etagCollectionsState, url) =>
      etagCollectionsState.etags.find((etag) => etag.referenceName === url)
        ?.etag || '',
  ),
};

export const { reducer: etagCollectionsReducer } = slice;
export const { addCustomEtag } = slice.actions;
