import i18n from '../../../../../../../../i18n';
import { linkToNewTab } from '../../../../../../../../utils/helpers';

type DetermineOnConfirm = {
  topology?: Topology;
  pushToCustomContent: RTKMutation<DemosUpdatePayload, DemosPostResponse>;
  postTopology: RTKMutation<DemosPostPayload, DemosPostResponse>;
};

export const determineOnConfirm = async ({
  postTopology,
  pushToCustomContent,
  topology,
}: DetermineOnConfirm): Promise<void | null> => {
  const isInSync = topology?.inSyncWithDcloud;
  const link = topology?._links?.schedule?.href || '';

  if (isInSync) return;

  switch (topology?.status) {
    case 'CUSTOM_CONTENT':
    case 'SAVED_CONTENT':
      await pushToCustomContent({
        id: topology.demoId!,
        topology: { uid: topology.uid },
      });
      if (link !== '') {
        linkToNewTab(link);
      }
      break;

    case 'DRAFT':
      await postTopology(topology.uid);
      if (link !== '') {
        linkToNewTab(link);
      }
      break;

    default:
      return null;
  }
};

type DeterminePushModalConfirmButtonLabel = {
  isFormDirty: boolean;
};

export const determinePushModalConfirmButtonLabel = ({
  isFormDirty,
}: DeterminePushModalConfirmButtonLabel): string => {
  return isFormDirty
    ? i18n.t('buttons.discardChangesAndPush')
    : i18n.t('topologies.schedule.notInSync.confirmationButtonLabel');
};
