import React, { ReactElement } from 'react';
import { ConfirmModal } from '../../../../../../Modals';
import i18n from '../../../../../../../i18n';
import {
  determineConfirmButtonColour,
  determineModalSubHeading,
} from '../../../../../../../pages/Topologies/components/SaveCustomContentModal/utils/utils';
import { PushModalProps } from './container';
import {
  determineOnConfirm,
  determinePushModalConfirmButtonLabel,
} from './utils/utils';
import { useRedirectToSchedule } from './hook/use-redirect-to-schedule';

export const PushModal = ({
  topology,
  isPushModalVisible,
  postTopology,
  pushToCustomContent,
  isLoading,
  clearIsPushModalVisible,
  isFormDirty,
}: PushModalProps): ReactElement => {
  useRedirectToSchedule({
    clearIsPushModalVisible,
    isPushModalVisible,
    topology,
  });

  if (!isPushModalVisible || !topology) {
    return <></>;
  }

  const handleOnClose = () => {
    clearIsPushModalVisible();
  };

  const handleOnConfirm = () => {
    determineOnConfirm({
      postTopology,
      pushToCustomContent,
      topology,
    });
  };

  return (
    <ConfirmModal
      show={!!topology}
      heading={i18n.t('topologies.schedule.notInSync.heading')}
      subHeading={determineModalSubHeading({
        isFormDirty,
        topologyName: topology.name,
      })}
      onCancel={handleOnClose}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      loading={isLoading}
      confirmButtonLabel={determinePushModalConfirmButtonLabel({ isFormDirty })}
      confirmButtonColour={determineConfirmButtonColour({ isFormDirty })}
      cancelButtonLabel={i18n.t('buttons.cancel')}
      clickScreenToClose={true}
      testId="push-modal"
    />
  );
};
