import React, { ReactElement, useState } from 'react';
import { NavLinks, ThemeSwitch } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { MainRoutes } from '../../../constants/routes';
import UserPreferencesLink from '../components/UserPreferences/UserPreferencesLink';
import { Header } from '../../Header';
import ConnectedUserPreferencesModal from '../components/UserPreferences/container';
import { NavigationButtons } from './components/NavigationButtons';
import { Clone } from './components/Clone';
import { TopologyTitle } from './components/TopologyTitle';
import { ConnectedPush } from './components/Push/container';
import { Schedule } from './components/Schedule';

type EditHeaderProps = {
  title: string;
};

export function EditHeader({ title }: EditHeaderProps): ReactElement {
  const { t } = useTranslation();
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);

  const openSettingsModal = (event: { preventDefault: VoidFunction }) => {
    event.preventDefault();
    setSettingsOpen(true);
  };
  return (
    <Header
      autoHideLink={true}
      logoLinkTo={MainRoutes.Main}
      title={t('siteTitle')}
      left={<TopologyTitle title={title} />}
      center={<NavigationButtons />}
      right={
        <>
          <ConnectedPush />
          <Schedule />
          <Clone />
          <NavLinks
            links={[
              {
                label: t('configuration.header.links.home'),
                to: MainRoutes.Main,
              },
              {
                label: t('configuration.header.links.topologies'),
                to: MainRoutes.Topologies,
              },
            ]}
          />
          <UserPreferencesLink onClick={openSettingsModal} />
          <ThemeSwitch />
          {isSettingsOpen && (
            <ConnectedUserPreferencesModal
              onClose={() => setSettingsOpen(false)}
            />
          )}
        </>
      }
    />
  );
}
