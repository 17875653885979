import { useEffect } from 'react';

export type UseFocusBox = {
  isSelected: boolean;
  nodeRef: React.RefObject<HTMLDivElement>;
};

export const useFocusBox = ({ isSelected, nodeRef }: UseFocusBox): void => {
  useEffect(() => {
    if (isSelected) {
      nodeRef?.current?.focus();
    }
  }, [isSelected, nodeRef]);
};
