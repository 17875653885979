import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../../Tooltip';
import styles from './index.module.css';

export const PushInSync = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Tooltip
      position="down"
      id="pushFromHeaderTooltipInSync"
      text={t('topologies.push.inSyncWithDcloud')}
      size="medium"
    >
      <Icon name="check-outline" className={styles.checkIcon} />
    </Tooltip>
  );
};
