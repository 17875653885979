import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Tooltip } from '../../../../../../../../Tooltip';
import styles from '../../index.module.css';

export const DisabledScheduleButton = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={styles.disabledScheduleButtonWrapper}>
      <Tooltip
        position="down"
        id="scheduleFromHeaderTooltip"
        text={t('topologies.schedule.notOwnerTooltip')}
        size="medium"
      >
        <Icon name="info-outline" className={styles.icon} />
      </Tooltip>

      <button
        type="button"
        className={classnames(
          styles.scheduleButton,
          styles.disabledScheduleButton,
        )}
      >
        {t('buttons.schedule')}
      </button>
    </div>
  );
};
