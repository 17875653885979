import { useEffect, useState } from 'react';

type UseControlPushSpinner = {
  isPushing: boolean;
  isPushComplete: boolean;
};

export const useIsPushingAndRefreshingData = ({
  isPushing,
  isPushComplete,
}: UseControlPushSpinner): boolean => {
  const [isPushingAndRefreshingData, setIsPushingAndRefreshingData] =
    useState(false);

  useEffect(() => {
    if (isPushing) {
      setIsPushingAndRefreshingData(true);
    }
  }, [isPushing]);

  useEffect(() => {
    if (isPushComplete) {
      setIsPushingAndRefreshingData(false);
    }
  }, [isPushComplete]);

  return isPushingAndRefreshingData;
};
