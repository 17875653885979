import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store';
import { FullScreenSpinner } from '../../../components/Loaders/FullScreenSpinner';
import { getCurrentUserState } from '../../../redux/configuration/currentUser/slice';
import { topologiesApi } from '../../../redux/topologies/api';
import { Configuration } from '.';

function ConfigurationContainer({
  userRoles,
}: ConfigurationProps): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { isLoading, data: topology } =
    topologiesApi.endpoints.getTopology.useQueryState(topologyUid);

  if (!topology || isLoading) {
    return <FullScreenSpinner />;
  }

  return <Configuration topologyToEdit={topology} userRoles={userRoles} />;
}

const mapStateToProps = (state: RootState) => ({
  userRoles: getCurrentUserState(state).userRoles,
});

type ConfigurationContainerProps = {
  topologyToEdit: Topology;
};

export type ConfigurationProps = ReturnType<typeof mapStateToProps> &
  ConfigurationContainerProps;

export const ConnectedConfigurationContainer = connect(mapStateToProps)(
  ConfigurationContainer,
);
