import React, { ReactElement } from 'react';
import { Button } from 'dcloud-shared-ui';
import i18n from '../../../../../../../i18n';
import styles from './index.module.css';
import { CloneTopologyButtonProps } from './container';
import { handleCloneTopology } from './utils/utils';

const CloneTopologyButton = ({
  onClone,
  topology,
}: CloneTopologyButtonProps): ReactElement => {
  const COPY_BUTTON_LABEL = i18n.t('topologies.clone.cloneButtonLabel');

  return (
    <>
      <Button
        className={styles.cloneTopologyButton}
        colour="ghost"
        onClick={() => {
          handleCloneTopology({ onClone, topology });
        }}
      >
        {COPY_BUTTON_LABEL}
      </Button>
    </>
  );
};

export default CloneTopologyButton;
