import { dcloudApi } from '../../../api';
import { MAIL_SERVERS, TOPOLOGIES_PATH } from '../../../../api/paths';
import i18n from '../../../../i18n';
import { TAGS } from '../../../api/constants';

export const mailServerApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    addMailServer: build.mutation<MailServer, MailServersPostPayload>({
      invalidatesTags: [TAGS.MAIL_SERVER, TAGS.TOPOLOGY],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('traffic.mailServer.add.error'),
        method: 'post',
        successMessage: i18n.t('traffic.mailServer.add.success'),
        url: `${MAIL_SERVERS}`,
      }),
    }),
    deleteMailServer: build.mutation<undefined, string>({
      invalidatesTags: [
        TAGS.MAIL_SERVER,
        TAGS.VIRTUAL_MACHINE_TARGETS,
        TAGS.TOPOLOGY,
      ],
      query: (uid) => ({
        errorMessage: i18n.t('traffic.mailServer.delete.error'),
        method: 'DELETE',
        successMessage: i18n.t('traffic.mailServer.delete.success'),
        url: `${MAIL_SERVERS}/${uid}`,
      }),
    }),
    getMailServers: build.query<FetchMailServersResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.MAIL_SERVER],
      query: (topologyUid) => ({
        errorMessage: i18n.t('traffic.mailServer.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${MAIL_SERVERS}`,
      }),
    }),
  }),
});

export const {
  useGetMailServersQuery,
  useAddMailServerMutation,
  useDeleteMailServerMutation,
} = mailServerApi;
