import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { stripGzipFromEtag } from '../api/utils';
import { getAccessToken } from '../auth';
import {
  addCustomEtag,
  customEtagSelectors,
} from './configuration/etagCollection/slice';
import { RootState } from './store';
import { addErrorToast, addSuccessToast } from './toast/slice';

export const createHeaders = (
  method: AxiosRequestConfig['method'],
  api: BaseQueryApi,
  authHeaderValue: string,
  url: string,
  overrideEtag?: string,
): AxiosRequestConfig['headers'] => {
  const headers: AxiosRequestConfig['headers'] = {
    Authorization: authHeaderValue,
  };
  if (method === 'put' || method === 'PUT') {
    if (overrideEtag) {
      return {
        ...headers,
        'If-Match': overrideEtag,
      };
    }

    return {
      ...headers,
      'If-Match': customEtagSelectors.getCustomEtag(
        api.getState() as RootState,
        { url },
      ),
    };
  }
  return headers;
};

export const generateAuthHeaderValue = (): string => {
  const token = getAccessToken();
  return `Bearer ${token}`;
};

export const stripAndsetEtag = (
  result: AxiosResponse<unknown>,
  api: BaseQueryApi,
  url?: string,
): void => {
  if (result.headers['etag']) {
    if (result.config.method === 'get') {
      api.dispatch(
        addCustomEtag({
          etag: stripGzipFromEtag(result.headers['etag']),
          referenceName: url!,
        }),
      );
    }
  }
};

export const dispatchSuccessToast = (
  successMessage: string,
  api: BaseQueryApi,
): void => {
  api.dispatch(addSuccessToast({ message: successMessage }));
};

export const dispatchErrorToast = (
  error: AxiosError,
  api: BaseQueryApi,
  errorMessage?: string,
  suppressErrorToast?: boolean,
): void => {
  if (suppressErrorToast) return;

  const errorToastMessage =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error as any).response?.data[0]?.message || errorMessage;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const logRef = (error as any).response?.data[0]?.logref;
  if (errorToastMessage) {
    api.dispatch(addErrorToast({ logRef, message: errorToastMessage }));
  }
};
