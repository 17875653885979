import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { GeneralForm } from '.';

type GeneralFormContainerOwnProps = {
  defaultValues?: TopologyGeneralFields;
  onHandleSubmit: (data: TopologyGeneralFields) => void;
  updating: boolean;
};

const mapStateToProps = (
  _: RootState,
  ownProps: GeneralFormContainerOwnProps,
) => ({
  ...ownProps,
});

export type GeneralFormProps = ReturnType<typeof mapStateToProps> &
  GeneralFormContainerOwnProps;

export const GeneralFormContainer = connect(mapStateToProps)(GeneralForm);
