import React, { ReactElement } from 'react';
import { ClonedTopologyLinkBody } from './ClonedTopologyLinkBody';
import { ClonedTopologyLinkFooter } from './ClonedTopologyLinkFooter';

export type ClonedTopologyLinkProps = {
  clonedTopologyUid: string;
  clearClonedTopologyUid: VoidFunction;
};

export const ClonedTopologyLink = ({
  clonedTopologyUid,
  clearClonedTopologyUid,
}: ClonedTopologyLinkProps): ReactElement => {
  return (
    <>
      <ClonedTopologyLinkBody
        clonedTopologyUid={clonedTopologyUid}
        clearClonedTopologyUid={clearClonedTopologyUid}
      />
      <ClonedTopologyLinkFooter
        clearClonedTopologyUid={clearClonedTopologyUid}
      />
    </>
  );
};
