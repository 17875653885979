import { connect } from 'react-redux';
import {
  setTopologyToClone,
  topologiesSelectors,
} from '../../../../../../../redux/topologies/slice';
import { RootState } from '../../../../../../../redux/store';
import CloneTopologyButton from '.';

const mapDispatchToProps = {
  onClone: setTopologyToClone,
};

const mapStateToProps = (state: RootState) => ({
  topology: topologiesSelectors.getTopologyToEdit(state),
});

export const CloneTopologyButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloneTopologyButton);

export type CloneTopologyButtonProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
