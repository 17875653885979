import { combineReducers } from 'redux';
import { accessRoleAssignmentsReducer } from './sharing/slice';
import { hardwareReducer } from './hardware/slice';
import { licencesReducer } from './licences/slice';
import { networksReducer } from './networks/slice';
import { startStopOrderReducer } from './start-stop-order/slice';
import { externalDnsReducer } from './traffic/external-dns/slice';
import { inboundProxyRulesReducer } from './traffic/inbound-proxy-rules/slice';
import { mailServersReducer } from './traffic/mail-server/slice';
import { natRulesReducer } from './traffic/nat-rules/slice';
import { virtualMachineEntitiesReducer } from './virtual-machine-entities/slice';
import { inventoryTemplatesReducer } from './templates/slice';
import { topologyLimitsReducer } from './topologyLimits/slice';
import { saasReducer } from './saas/slice';

export const configurationReducer = combineReducers({
  accessRoleAssignments: accessRoleAssignmentsReducer,
  externalDns: externalDnsReducer,
  hardware: hardwareReducer,
  inboundProxyRules: inboundProxyRulesReducer,
  inventoryTemplate: inventoryTemplatesReducer,
  licences: licencesReducer,
  mailServers: mailServersReducer,
  natRules: natRulesReducer,
  networks: networksReducer,
  saas: saasReducer,
  startStopOrder: startStopOrderReducer,
  topologyLimits: topologyLimitsReducer,
  virtualMachineEntities: virtualMachineEntitiesReducer,
});
