import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface TopologiesState {
  clonedTopologyUid?: string;
  isPushModalVisible?: Topology;
  isScheduleModalVisible?: Topology;
  shouldConfirmDelete?: boolean;
  topologyToClone?: TopologyToClone;
  topologyToDelete?: Topology;
  topologyToEdit?: Topology;
  topologyToSave?: Topology;
  topologyToUpdate?: Topology;
}

export const initialState: TopologiesState = {
  clonedTopologyUid: undefined,
  isPushModalVisible: undefined,
  isScheduleModalVisible: undefined,
  shouldConfirmDelete: undefined,
  topologyToClone: undefined,
  topologyToDelete: undefined,
  topologyToEdit: undefined,
  topologyToSave: undefined,
  topologyToUpdate: undefined,
};

export const topologiesSlice = createSlice({
  initialState,
  name: 'topologies',
  reducers: {
    clearClonedTopologyUid: (state) => {
      state.clonedTopologyUid = undefined;
    },
    clearIsPushModalVisible: (state) => {
      state.isPushModalVisible = undefined;
    },
    clearIsScheduleModalVisible: (state) => {
      state.isScheduleModalVisible = undefined;
    },
    clearTopologyToClone: (state) => {
      state.topologyToClone = undefined;
    },
    clearTopologyToDelete: (state) => {
      state.topologyToDelete = undefined;
      state.shouldConfirmDelete = undefined;
    },
    clearTopologyToSave: (state) => {
      state.topologyToSave = undefined;
    },
    clearTopologyToUpdate: (state) => {
      state.topologyToUpdate = undefined;
    },
    reset: () => initialState,
    setClonedTopologyUid: (state, action: PayloadAction<string>) => {
      state.clonedTopologyUid = action.payload;
    },
    setIsPushModalVisible: (state, action: PayloadAction<Topology>) => {
      state.isPushModalVisible = action.payload;
    },
    setIsScheduleModalVisible: (state, action: PayloadAction<Topology>) => {
      state.isScheduleModalVisible = action.payload;
    },
    setShouldConfirmDelete: (state, action: PayloadAction<boolean>) => {
      state.shouldConfirmDelete = action.payload;
    },
    setTopologyToClone: (state, action: PayloadAction<TopologyToClone>) => {
      state.topologyToClone = action.payload;
    },
    setTopologyToDelete: (state, action: PayloadAction<Topology>) => {
      state.topologyToDelete = action.payload;
    },
    setTopologyToEdit: (state, action: PayloadAction<Topology>) => {
      state.topologyToEdit = action.payload;
    },
    setTopologyToSave: (state, action: PayloadAction<Topology>) => {
      state.topologyToSave = action.payload;
    },
    setTopologyToUpdate: (state, action: PayloadAction<Topology>) => {
      state.topologyToUpdate = action.payload;
    },
  },
});

export const {
  reducer: topologiesReducer,
  actions: {
    clearClonedTopologyUid,
    clearTopologyToClone,
    clearTopologyToDelete,
    clearIsPushModalVisible,
    clearTopologyToSave,
    clearIsScheduleModalVisible,
    clearTopologyToUpdate,
    reset,
    setClonedTopologyUid,
    setShouldConfirmDelete,
    setTopologyToClone,
    setTopologyToDelete,
    setTopologyToEdit,
    setIsPushModalVisible,
    setTopologyToSave,
    setIsScheduleModalVisible,
    setTopologyToUpdate,
  },
} = topologiesSlice;

const getTopologies = (state: RootState) => state.topologies;

export const topologiesSelectors = {
  getClonedTopologyUid: createSelector(
    getTopologies,
    ({ clonedTopologyUid }) => clonedTopologyUid,
  ),
  getIsPushModalVisible: createSelector(
    getTopologies,
    ({ isPushModalVisible }) => isPushModalVisible,
  ),
  getIsScheduleModalVisible: createSelector(
    getTopologies,
    ({ isScheduleModalVisible }) => isScheduleModalVisible,
  ),
  getIsTopologyToCloneCalledFromConfigurationPage: createSelector(
    getTopologies,
    ({ topologyToClone }) => !!topologyToClone?.isConfigurationPage,
  ),
  getShouldConfirmDelete: createSelector(
    getTopologies,
    ({ shouldConfirmDelete }) => shouldConfirmDelete,
  ),
  getTopologyToClone: createSelector(
    getTopologies,
    ({ topologyToClone }) => topologyToClone,
  ),
  getTopologyToDelete: createSelector(
    getTopologies,
    ({ topologyToDelete }) => topologyToDelete,
  ),
  getTopologyToEdit: createSelector(
    getTopologies,
    ({ topologyToEdit }) => topologyToEdit,
  ),
  getTopologyToSave: createSelector(
    getTopologies,
    ({ topologyToSave }) => topologyToSave,
  ),
  getTopologyToUpdate: createSelector(
    getTopologies,
    ({ topologyToUpdate }) => topologyToUpdate,
  ),
};
