import { dcloudApi } from '../api';
import i18n from '../../i18n';
import { SCENARIOS_PATH, TOPOLOGIES_PATH } from '../../api/paths';
import { TAGS } from '../api/constants';
import { setScenariosToSave } from './slice';

export const scenariosApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getScenarios: build.query<Scenarios, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setScenariosToSave(data));
        } catch (err) {
          return;
        }
      },
      providesTags: [TAGS.SCENARIOS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('scenarios.fetch.error'),
        method: 'GET',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${SCENARIOS_PATH}`,
      }),
    }),
    updateScenarios: build.mutation<Scenarios, ScenariosPayload>({
      invalidatesTags: [TAGS.SCENARIOS, TAGS.TOPOLOGY],
      query: (payload) => ({
        data: {
          ...payload.scenariosToSave,
          topology: { uid: payload.topologyUid },
        },
        errorMessage: i18n.t('scenarios.save.error'),
        method: 'PUT',
        successMessage: i18n.t('scenarios.save.success'),
        url: `${TOPOLOGIES_PATH}/${payload.topologyUid}/scenario`,
      }),
    }),
  }),
});

export const { useGetScenariosQuery, useUpdateScenariosMutation } =
  scenariosApi;
