import React, { ReactElement } from 'react';
import { Button } from 'dcloud-shared-ui';
import i18n from '../../../../../../i18n';

export type ClonedTopologyLinkFooterProps = {
  clearClonedTopologyUid: VoidFunction;
};

export const ClonedTopologyLinkFooter = ({
  clearClonedTopologyUid,
}: ClonedTopologyLinkFooterProps): ReactElement => {
  const CLOSE_BUTTON_LABEL = i18n.t('buttons.close');

  return (
    <div className="flex flex-right">
      <Button
        className="dbl-margin-top"
        colour="ghost"
        size="large"
        onClick={clearClonedTopologyUid}
      >
        {CLOSE_BUTTON_LABEL}
      </Button>
    </div>
  );
};
