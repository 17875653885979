import i18n from '../../../../../i18n';

type DetermineModalSubHeading = {
  isFormDirty: boolean;
  topologyName: string;
};

export const determineModalSubHeading = ({
  isFormDirty,
  topologyName,
}: DetermineModalSubHeading): string =>
  isFormDirty
    ? i18n.t(`topologies.push.modalSubheadingWithWarning`)
    : i18n
        .t(`topologies.updatePush.modalSubheading`)
        .replace(':topology', topologyName);

type DetermineConfirmButtonLabel = {
  isFormDirty: boolean;
  topologyToSave?: Topology;
};

export const determineConfirmButtonLabel = ({
  isFormDirty,
  topologyToSave,
}: DetermineConfirmButtonLabel): string =>
  topologyToSave
    ? isFormDirty
      ? i18n.t('buttons.discardChangesAndSave')
      : i18n.t('buttons.save')
    : isFormDirty
    ? i18n.t('buttons.discardChangesAndPush')
    : i18n.t('buttons.push');

type DetermineConfirmButtonColour = {
  isFormDirty: boolean;
};

export const determineConfirmButtonColour = ({
  isFormDirty,
}: DetermineConfirmButtonColour): 'danger' | 'success' =>
  isFormDirty ? 'danger' : 'success';
