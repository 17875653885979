import React, { ReactElement, useMemo } from 'react';
import { format } from 'date-fns';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../components/Table';
import { TopologyActions } from '../TopologyActions';
import {
  TOPOLOGIES_LAST_UPDATED_FORMAT,
  TOPOLOGIES_LIMIT,
} from '../../constants';
import { addOnPushActionToTopologies } from '../../utils';
import { TopologiesListIconsCol } from '../TopologiesListIconsCol';
import { LinkToTopology } from './LinkToTopology';
import styles from './TopologiesTable.module.css';
import { TwoLineTruncatedCell } from './TwoLineTruncatedCell';
import { StatusCell } from './StatusCell';

export interface TopologiesTableProps {
  onClone: TopologyCloneAction;
  onDelete: TopologyAction;
  onPush: TopologyAction;
  onUpdate: TopologyAction;
  topologies: Topology[];
}

export function TopologiesTable({
  onClone,
  onDelete,
  onPush,
  onUpdate,
  topologies,
}: TopologiesTableProps): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo<Column<TopologyWithOnPushActions>[]>(
    () => [
      {
        Cell: TopologiesListIconsCol,
        Header: () => '',
        accessor: 'sharedWithCurrentUser',
        maxWidth: 50,
        minWidth: 30,
      },
      {
        Cell: LinkToTopology,
        Header: () => t('common.name'),
        accessor: 'name',
        minWidth: 200,
      },
      {
        Cell: TwoLineTruncatedCell,
        Header: () => t('common.description'),
        accessor: 'description',
        minWidth: 200,
      },
      {
        Cell: ({ value }) => value || '',
        Header: () => t('common.demoId'),
        accessor: 'demoId',
        width: 100,
      },
      {
        Header: () => t('topologies.owner'),
        accessor: 'owner',
        minWidth: 160,
      },
      {
        Header: () => t('common.dataCenter'),
        accessor: 'datacenter',
        width: 100,
      },
      {
        Cell: ({ value }) =>
          format(new Date(value), TOPOLOGIES_LAST_UPDATED_FORMAT),
        Header: () => t('common.lastUpdated'),
        accessor: 'lastUpdated',
        id: 'lastUpdated',
        minWidth: 100,
      },
      {
        Header: () => t('common.activeTemplate'),
        accessor: (row) =>
          row.activeTemplate ? t('common.yes') : t('common.no'),
        id: 'activeTemplate',
        width: 90,
      },
      {
        Header: () => t('common.demoPublished'),
        accessor: (row) =>
          row.demoPublished ? t('common.yes') : t('common.no'),
        id: 'demoPublished',
        maxWidth: 85,
      },
      {
        Cell: StatusCell,
        Header: () => t('common.status'),
        accessor: 'status',
        id: 'status',
        minWidth: 230,
      },
    ],
    [t],
  );

  const topologiesWithOnPushActions = useMemo<TopologyWithOnPushActions[]>(
    () => addOnPushActionToTopologies(topologies, onPush, onUpdate),
    [onPush, onUpdate, topologies],
  );

  function renderActions(topology: Topology): ReactElement {
    return (
      <TopologyActions
        uid={topology.uid}
        onDelete={() => onDelete(topology)}
        canClone={!!topology._links.clones}
        onClone={() => onClone({ isConfigurationPage: false, topology })}
      />
    );
  }

  return (
    <div className={styles.outer}>
      <div className={styles.root} data-testid="topologies-table">
        <Table<TopologyWithOnPushActions>
          className={styles.table}
          actions={renderActions}
          columns={columns}
          data={topologiesWithOnPushActions}
          initialState={{
            pageSize: TOPOLOGIES_LIMIT,
            sortBy: [{ desc: true, id: 'lastUpdated' }],
          }}
        />
      </div>
    </div>
  );
}
