import React, { ReactElement } from 'react';
import { Icon } from 'dcloud-shared-ui';
import { Tooltip } from '../../../../../../Tooltip';
import {
  determineButtonClasses,
  determineButtonLabel,
  determineButtonWrapperClasses,
  determineIconWrapperClasses,
  determineTooltipText,
} from './utils/utils';
import styles from './index.module.css';

export type PushButtonProps = {
  isDisabled?: boolean;
  isEditingAsset?: boolean;
  isPushingUpdates?: boolean;
  onClick?: VoidFunction;
};

export const PushButton = ({
  onClick,
  isPushingUpdates,
  isDisabled,
  isEditingAsset,
}: PushButtonProps): ReactElement => {
  const TOOLTIP_TEXT = determineTooltipText({
    isDisabled,
    isEditingAsset,
    isPushingUpdates,
  });
  const BUTTON_LABEL = determineButtonLabel(isPushingUpdates);
  const BUTTON_WRAPPER_CLASSES = determineButtonWrapperClasses({ isDisabled });
  const ICON_CLASSES = determineIconWrapperClasses({ isDisabled });
  const BUTTON_CLASSES = determineButtonClasses({
    isDisabled,
    isPushingUpdates,
  });

  return (
    <div className={styles.root}>
      <Tooltip
        position="down"
        id="pushFromHeaderTooltip"
        text={TOOLTIP_TEXT}
        size="medium"
      >
        <Icon name="info-outline" className={ICON_CLASSES} />
      </Tooltip>
      <span className={BUTTON_WRAPPER_CLASSES}>
        <button
          type="button"
          className={BUTTON_CLASSES}
          onClick={onClick}
          disabled={isDisabled}
        >
          {BUTTON_LABEL}
        </button>
      </span>
    </div>
  );
};
