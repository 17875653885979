import classNames from 'classnames';
import styles from '../index.module.css';
import i18n from '../../../../../../../../i18n';

export const determineButtonLabel = (isPushingUpdates?: boolean): string =>
  isPushingUpdates ? 'Push Updates' : 'Push';

type DetermineButtonClasses = {
  isPushingUpdates?: boolean;
  isDisabled?: boolean;
};

export const determineButtonClasses = ({
  isPushingUpdates,
  isDisabled,
}: DetermineButtonClasses): string =>
  classNames(styles.pushButton, {
    [styles.pushUpdatesButton]: isPushingUpdates,
    [styles.pushButtonDisabled]: isDisabled,
  });

type DetermineButtonWrapperClasses = {
  isDisabled?: boolean;
};

export const determineButtonWrapperClasses = ({
  isDisabled,
}: DetermineButtonWrapperClasses): string =>
  classNames({
    [styles.buttonWrapperDisabled]: isDisabled,
  });

type DetermineIconClasses = {
  isDisabled?: boolean;
};

export const determineIconWrapperClasses = ({
  isDisabled,
}: DetermineIconClasses): string =>
  classNames(styles.icon, {
    [styles.iconDisabled]: isDisabled,
  });

type DetermineTooltipText = {
  isPushingUpdates?: boolean;
  isDisabled?: boolean;
  isEditingAsset?: boolean;
};

export const determineTooltipText = ({
  isDisabled,
  isPushingUpdates,
  isEditingAsset,
}: DetermineTooltipText): string => {
  if (isEditingAsset) {
    return isPushingUpdates
      ? i18n.t('topologies.updatePush.disabledButtonTooltipWhenEditingAsset')
      : i18n.t('topologies.push.disabledButtonTooltipWhenEditingAsset');
  } else {
    return isDisabled
      ? isPushingUpdates
        ? i18n.t('topologies.updatePush.disabledButtonTooltip')
        : i18n.t('topologies.push.disabledButtonTooltip')
      : isPushingUpdates
      ? i18n.t('topologies.push.pushUpdatesButtonText')
      : i18n.t('topologies.push.pushButtonText');
  }
};
