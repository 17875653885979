import React, { ReactElement } from 'react';
import i18n from '../../i18n';
import {
  PushActionPushButton,
  PushActionUpdateButton,
} from './components/TopologiesPushActions';
import { DisabledPushActionPushButton } from './components/TopologiesPushActions/DisabledPushActionPushButton';
import { DisabledPushActionUpdateButton } from './components/TopologiesPushActions/DisabledPushActionUpdateButton';

type DeterminePushActionsReturnStrings =
  | 'PUSH'
  | 'PUSH_DISABLED'
  | 'PUSH_UPDATES'
  | 'PUSH_UPDATES_DISABLED';

const PUSH_ACTIONS: Record<string, DeterminePushActionsReturnStrings> = {
  PUSH: 'PUSH',
  PUSH_DISABLED: 'PUSH_DISABLED',
  PUSH_UPDATES: 'PUSH_UPDATES',
  PUSH_UPDATES_DISABLED: 'PUSH_UPDATES_DISABLED',
};

export const determinePushAction = (
  { inSyncWithDcloud, _links, status, uid }: Topology,
  onPush?: VoidFunction,
  onUpdate?: VoidFunction,
  isHeader?: boolean,
): ReactElement | null | DeterminePushActionsReturnStrings => {
  if (inSyncWithDcloud || inSyncWithDcloud === undefined) {
    return null;
  }

  switch (status) {
    case 'DRAFT':
      return 'push' in _links ? (
        isHeader ? (
          PUSH_ACTIONS.PUSH
        ) : (
          <PushActionPushButton onClick={onPush ? onPush : () => null} />
        )
      ) : isHeader ? (
        PUSH_ACTIONS.PUSH_DISABLED
      ) : (
        <DisabledPushActionPushButton
          tooltipId={`disable-push-${uid}`}
          tooltipMessage={i18n.t('topologies.push.disabledButtonTooltip')}
        />
      );
    case 'CUSTOM_CONTENT':
    case 'SAVED_CONTENT':
      return 'updatePush' in _links ? (
        isHeader ? (
          PUSH_ACTIONS.PUSH_UPDATES
        ) : (
          <PushActionUpdateButton onClick={onUpdate ? onUpdate : () => null} />
        )
      ) : isHeader ? (
        PUSH_ACTIONS.PUSH_UPDATES_DISABLED
      ) : (
        <DisabledPushActionUpdateButton
          tooltipId={`disable-push-update-${uid}`}
          tooltipMessage={i18n.t('topologies.updatePush.disabledButtonTooltip')}
        />
      );
    default:
      return null;
  }
};

export const addOnPushActionToTopologies = (
  topologies: Topology[],
  onPush: TopologyAction,
  onUpdate: TopologyAction,
): TopologyWithOnPushActions[] => {
  return topologies.map((topology) => ({
    ...topology,
    onPush: () => onPush(topology),
    onUpdate: () => onUpdate(topology),
  }));
};

export const getTopologyOwnersEmail = (
  accessRoleAssignments?: AccessRoleAssignment[],
): string => {
  return (
    accessRoleAssignments?.filter(
      (accessRoleAssignment) => accessRoleAssignment.accessRole === 'OWNER',
    )[0]?.email || ''
  );
};

export const prepTopologiesWithOwner = (
  topologies: Topology[] | undefined,
): Topology[] => {
  if (!topologies) return [];

  return topologies.map((topology) => {
    return {
      ...topology,
      owner: getTopologyOwnersEmail(topology.accessRoleAssignments),
    };
  });
};

type DetermineIsEditingAsset = {
  pathname?: string;
};

export const determineIsEditingAsset = ({
  pathname,
}: DetermineIsEditingAsset): boolean => {
  if (pathname !== undefined) {
    const parts = pathname.split('/');
    const EDIT_PART = 'edit';
    const CONFIGURATION_PART = 'configuration';
    const VIRTUAL_MACHINES = 'virtual-machines';
    const HARDWARE = 'hardware';
    const INTEGRATIONS = 'integrations';

    if (parts[1] !== EDIT_PART || parts[3] !== CONFIGURATION_PART) return false;

    const currentPage = parts[4];
    if (currentPage === VIRTUAL_MACHINES || currentPage === HARDWARE) {
      if (parts[5]?.length > 0) {
        return true;
      }
    }

    if (currentPage === INTEGRATIONS) {
      if (parts[6]?.length > 0) {
        return true;
      }
    }
  }

  return false;
};
