import { connect } from 'react-redux';
import {
  clearClonedTopologyUid,
  topologiesSelectors,
} from '../../../../redux/topologies/slice';
import { RootState } from '../../../../redux/store';
import { ClonedTopologyLinkModal } from '.';

const mapDispatchToProps = {
  clearClonedTopologyUid,
};

const mapStateToProps = (state: RootState) => ({
  clonedTopologyUid: topologiesSelectors.getClonedTopologyUid(state),
});

export const ClonedTopologyLinkModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClonedTopologyLinkModal);

export type ClonedTopologyLinkModalProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
