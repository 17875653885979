import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsFormDirty } from '../../../../redux/configuration/isFormDirty/slice';

type UseUpdateFormDirtyStatus = {
  isDirty: boolean;
};

export const useUpdateFormDirtyStatus = ({
  isDirty,
}: UseUpdateFormDirtyStatus): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    isDirty ? dispatch(setIsFormDirty(true)) : dispatch(setIsFormDirty(false));

    return () => {
      dispatch(setIsFormDirty(false));
    };
  }, [isDirty, dispatch]);
};
