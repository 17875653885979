import React, { ReactElement } from 'react';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { ReactComponent as Laptop } from '../../../../../assets/images/icons/Laptop.svg';
import { ReactComponent as AccessPoint } from '../../../../../assets/images/icons/AccessPoint.svg';
import { ReactComponent as Cloud } from '../../../../../assets/images/icons/Cloud.svg';
import { ReactComponent as Firewall } from '../../../../../assets/images/icons/Firewall.svg';
import { ReactComponent as GenericBuilding } from '../../../../../assets/images/icons/GenericBuilding.svg';
import { ReactComponent as Router } from '../../../../../assets/images/icons/Router.svg';
import { ReactComponent as Server } from '../../../../../assets/images/icons/Server.svg';
import { ReactComponent as Storage } from '../../../../../assets/images/icons/Storage.svg';
import { ReactComponent as Switch } from '../../../../../assets/images/icons/Switch.svg';
import { ReactComponent as WLC } from '../../../../../assets/images/icons/WLC.svg';
import { ReactComponent as SaaSConnect } from '../../../../../assets/images/icons/SaaSConnect.svg';
import { ReactComponent as PublicCloud } from '../../../../../assets/images/icons/PublicCloudDefault.svg';
import { ReactComponent as Telephone } from '../../../../../assets/images/icons/Telephone.svg';

export type DetermineIcon = {
  uid: string;
  display?: DiagramDisplay;
  className?: string;
};

export const determineStaticIconForDiagram = ({
  uid,
  display,
  className,
}: DetermineIcon): ReactElement => {
  switch (display?.icon) {
    case 'Laptop':
      return (
        <Laptop
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Access Point':
      return (
        <AccessPoint
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Cloud':
      return (
        <Cloud
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Firewall':
      return (
        <Firewall
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Generic Building':
      return (
        <GenericBuilding
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Router':
      return (
        <Router
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Server':
      return (
        <Server
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Storage':
      return (
        <Storage
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Switch':
      return (
        <Switch
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Telephone':
      return (
        <Telephone
          data-component={uid}
          id={`${display.icon}-${uid} className={className}`}
        />
      );
    case 'WLC':
      return (
        <WLC
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'SaaS Connect':
      return (
        <SaaSConnect
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    case 'Public Cloud':
      return (
        <PublicCloud
          data-component={uid}
          id={`${display.icon}-${uid}`}
          className={className}
        />
      );
    default:
      return (
        <Laptop
          data-component={uid}
          id={`UNKNOWN-ICON-${uid}`}
          className={className}
        />
      );
  }
};

type IconNodeHandleOnClickProps = {
  expired: boolean;
  isReadOnly: boolean;
  setLeftPanelType: ActionCreatorWithOptionalPayload<
    SessionPanel | undefined,
    string
  >;
  setLeftPanelDeviceUid: ActionCreatorWithOptionalPayload<
    string | undefined,
    string
  >;
  uid: string;
};

export const iconNodeHandleOnClick = ({
  expired,
  isReadOnly,
  setLeftPanelType,
  setLeftPanelDeviceUid,
  uid,
}: IconNodeHandleOnClickProps): void => {
  if (!expired && isReadOnly) {
    setLeftPanelType('icon');
    setLeftPanelDeviceUid(uid);
  }
};

type UpdateIconNode = {
  updateNode: RTKMutation<UpdateDiagramNodeProps, UpdateDiagramNodeProps>;
  display?: DiagramDisplay;
  uid: string;
  x1: number;
  y1: number;
};

export const updateIconNode = ({
  updateNode,
  display,
  uid,
  x1,
  y1,
}: UpdateIconNode): void =>
  updateNode({
    display: {
      data: display?.data,
      drawingType: display?.drawingType,
      icon: display?.icon,
      name: display?.name,
      x1,
      y1,
    },
    nodeUid: uid,
  });
