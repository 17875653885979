import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { linkToNewTab } from '../../../../../../../../utils/helpers';

type UseIsDataPostedOrPushed = {
  clearIsPushModalVisible: ActionCreatorWithoutPayload<string>;
  isPushModalVisible: Topology | undefined;
  topology: Topology | undefined;
};

export const useRedirectToSchedule = ({
  clearIsPushModalVisible,
  isPushModalVisible,
  topology,
}: UseIsDataPostedOrPushed): void => {
  useEffect(() => {
    if (topology?._links?.schedule?.href && isPushModalVisible) {
      linkToNewTab(topology?._links?.schedule?.href);
      clearIsPushModalVisible();
    }
  }, [topology, isPushModalVisible, clearIsPushModalVisible]);
};
