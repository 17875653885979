import { dcloudApi } from '../api';
import {
  DIAGRAM_PATH,
  HARDWARE_PATH,
  NETWORKS_PATH,
  NODES_PATH,
  TOPOLOGIES_PATH,
  VMS_PATH,
} from '../../api/paths';
import i18n from '../../i18n';
import { store } from '../store';
import { topologiesSelectors } from '../topologies/slice';
import { TAGS } from '../api/constants';
import { setNetworkToDelete } from '../configuration/networks/slice';
import {
  addNodeToNodeDisplayReducer,
  decorateConnections,
  updateNodeDisplayReducer,
} from './utils';

export const diagramApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createNode: build.mutation<DiagramNode, DiagramNodeDisplayOnlyPayload>({
      async onQueryStarted(
        { topology: { uid }, isInvalidateDiagramTagNeeded },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: node } = await queryFulfilled;
          dispatch(
            diagramApi.util.updateQueryData(
              'getDiagram',
              uid,
              (response: DiagramResponse) =>
                addNodeToNodeDisplayReducer({
                  diagramResponse: response,
                  node,
                }),
            ),
          );
          if (isInvalidateDiagramTagNeeded) {
            dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM]));
          }
        } catch {
          dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM]));
        } finally {
          dispatch(diagramApi.util.invalidateTags([TAGS.TOPOLOGY]));
        }
      },
      query: ({ display, topology }) => ({
        data: { display, topology },
        errorMessage: i18n.t('diagram.add.error'),
        method: 'post',
        url: NODES_PATH,
      }),
    }),
    deleteHardwareFromDiagram: build.mutation<undefined, string>({
      invalidatesTags: [
        TAGS.HARDWARE_ITEMS,
        TAGS.TOPOLOGY_LIMITS,
        TAGS.DIAGRAM,
      ],
      query: (uid) => ({
        errorMessage: i18n.t('hardware.delete.error'),
        method: 'DELETE',
        successMessage: i18n.t('hardware.delete.success'),
        url: `${HARDWARE_PATH}/${uid}`,
      }),
    }),
    deleteIconNode: build.mutation<undefined, string>({
      invalidatesTags: [TAGS.DIAGRAM],
      query: (nodeUid) => ({
        errorMessage: i18n.t('diagram.delete.error'),
        method: 'delete',
        url: `${NODES_PATH}/${nodeUid}`,
      }),
    }),
    deleteNetworkFromDiagram: build.mutation<undefined, Network>({
      invalidatesTags: [TAGS.NETWORKS, TAGS.NETWORK, TAGS.DIAGRAM],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
        } finally {
          dispatch(setNetworkToDelete(undefined));
        }
      },
      query: ({ uid: networkUid }) => ({
        errorMessage: i18n.t('networks.delete.error'),
        method: 'delete',
        successMessage: i18n.t('networks.delete.success'),
        url: `${NETWORKS_PATH}/${networkUid}`,
      }),
    }),
    deleteNode: build.mutation<undefined, string>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const topology = topologiesSelectors.getTopologyToEdit(
          store.getState(),
        );
        await queryFulfilled;
        if (topology?.inSyncWithDcloud) {
          dispatch(diagramApi.util.invalidateTags([TAGS.TOPOLOGY]));
        }
      },
      query: (nodeUid) => ({
        errorMessage: i18n.t('diagram.delete.error'),
        method: 'delete',
        url: `${NODES_PATH}/${nodeUid}`,
      }),
    }),
    deleteVMFromDiagram: build.mutation<VirtualMachine, VmToDeletePayload>({
      invalidatesTags: [
        TAGS.VIRTUAL_MACHINES,
        TAGS.VIRTUAL_MACHINES_ALL,
        TAGS.TOPOLOGY_LIMITS,
        TAGS.DIAGRAM,
      ],
      query: ({ id }) => ({
        errorMessage: i18n.t('virtualMachines.delete.error'),
        method: 'delete',
        successMessage: i18n.t('virtualMachines.delete.success'),
        url: `${VMS_PATH}/${id}`,
      }),
    }),
    getDiagram: build.query<DiagramResponse, Topology['uid']>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.DIAGRAM],
      query: (topologyUid) => ({
        errorMessage: i18n.t('diagram.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${DIAGRAM_PATH}`,
      }),
      transformResponse: decorateConnections,
    }),
    getNode: build.query<DiagramNode, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.NODE],
      query: (nodeUid) => ({
        errorMessage: i18n.t('diagram.fetch.error'),
        method: 'get',
        url: `${NODES_PATH}/${nodeUid}`,
      }),
    }),
    updateNode: build.mutation<DiagramNode, UpdateDiagramNodeProps>({
      async onQueryStarted(
        { nodeUid, display, isInvalidateDiagramTagNeeded },
        { dispatch, queryFulfilled },
      ) {
        const topology = topologiesSelectors.getTopologyToEdit(
          store.getState(),
        );
        dispatch(
          diagramApi.util.updateQueryData(
            'getDiagram',
            topology!.uid,
            (response: DiagramResponse) =>
              updateNodeDisplayReducer({
                diagramResponse: response,
                display,
                nodeUid,
              }),
          ),
        );

        try {
          await queryFulfilled;
          if (isInvalidateDiagramTagNeeded) {
            dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM]));
          }
          if (topology?.inSyncWithDcloud) {
            dispatch(diagramApi.util.invalidateTags([TAGS.TOPOLOGY]));
          }
        } catch {
          dispatch(
            diagramApi.util.invalidateTags([TAGS.DIAGRAM, TAGS.TOPOLOGY]),
          );
        }
      },
      query: ({ display, nodeUid }) => ({
        data: { display },
        errorMessage: i18n.t('diagram.update.error'),
        method: 'patch',
        url: `${NODES_PATH}/${nodeUid}`,
      }),
    }),
  }),
});

export const {
  useCreateNodeMutation,
  useDeleteNetworkFromDiagramMutation,
  useDeleteNodeMutation,
  useDeleteHardwareFromDiagramMutation,
  useDeleteIconNodeMutation,
  useDeleteVMFromDiagramMutation,
  useGetDiagramQuery,
  useGetNodeQuery,
  useUpdateNodeMutation,
} = diagramApi;
