import React, { ReactElement } from 'react';
import { Modal } from '../../../../components';
import i18n from '../../../../i18n';
import { ClonedTopologyLink } from './ClonedTopologyLink';

export type ClonedTopologyLinkModalProps = {
  clearClonedTopologyUid: VoidFunction;
  clonedTopologyUid?: string;
};

export const ClonedTopologyLinkModal = ({
  clearClonedTopologyUid,
  clonedTopologyUid,
}: ClonedTopologyLinkModalProps): ReactElement => {
  const SUCCESS_MESSAGE = i18n.t('topologies.clone.success');

  if (!clonedTopologyUid) {
    return <></>;
  }

  return (
    <Modal
      title={SUCCESS_MESSAGE}
      show={!!clonedTopologyUid}
      size="default"
      onClose={clearClonedTopologyUid}
      clickScreenToClose={true}
    >
      <ClonedTopologyLink
        clonedTopologyUid={clonedTopologyUid}
        clearClonedTopologyUid={clearClonedTopologyUid}
      />
    </Modal>
  );
};
