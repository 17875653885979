export const TAGS = {
  ACCESS_ROLE_ASSIGNMENT: 'AccessRoleAssignment',
  ARCHITECTURES: 'architectures',
  CURRENT_USER: 'CurrentUser',
  DATA_CENTERS: 'DataCenters',
  DEMOS_BY_DC: 'DemosByDC',
  DEMO_TEMPLATE: 'TemplateDemo',
  DEMO_TEMPLATES: 'TemplatedInventoryDemos',
  DIAGRAM: 'Diagram',
  DNS_ASSETS: 'DnsAssets',
  DOCUMENTATION: 'Documentation',
  EVC_MODES: 'EvcModes',
  EXTERNAL_DNS: 'ExternalDnsRecords',
  GENERAL: 'General',
  HARDWARE_INVENTORY_SCRIPTS: 'HardwareInventoryScripts',
  HARDWARE_ITEM: 'HardwareItem',
  HARDWARE_ITEMS: 'HardwareItems',
  HARDWARE_START_ORDER: 'HardwareStartOrder',
  HARDWARE_START_ORDER_ITEM: 'HardwareStartOrderItem',
  INBOUND_PROXY_RULES: 'InboundProxyRules',
  INTEGRATION: 'Integration',
  INTEGRATIONS: 'Integrations',
  INTEGRATION_LICENSES: 'IntegrationLicenses',
  INTEGRATION_TAGS: 'IntegrationTags',
  INTEGRATION_TOPOLOGIES: 'IntegrationTopologies',
  INVENTORY_NETWORKS: 'InventoryNetworks',
  INVENTORY_SAAS_INTEGRATIONS: 'InternalSaasIntegrations',
  INVENTORY_TELEPHONY_ITEMS: 'InventoryTelephonyItems',
  INVENTORY_TEMPLATED_TOPOLOGIES: 'InventoryTemplatedTopolgies',
  LICENCE: 'Licence',
  LICENCES: 'Licences',
  MAIL_SERVER: 'MailServer',
  NAT_RULES: 'NatRules',
  NETWORK: 'Network',
  NETWORKS: 'Networks',
  NODE: 'Node',
  OPERATING_SYSTEMS: 'OperatingSystems',
  REMOTE_ACCESS: 'RemoteAccess',
  REMOTE_ACCESS_ITEM: 'RemoteAccessItem',
  SAAS_INTEGRATION: 'SaasIntegration',
  SAAS_INTEGRATIONS: 'SaasIntegrations',
  SAAS_TEMPLATE_AVAILABILITY_VALUES: 'SaasTemplateVisibilityValues',
  SCENARIOS: 'Scenarios',
  SESSIONS: 'Sessions',
  SESSION_VM_STATUS: 'SessionVmStatus',
  SRV_PROTOCOLS: 'SrvProtocols',
  TELEPHONY_ITEMS: 'TelephonyItems',
  TOPOLOGIES: 'Topologies',
  TOPOLOGY: 'Topology',
  TOPOLOGY_LIMITS: 'TopologyLimits',
  VIRTUAL_MACHINE: 'VirtualMachine',
  VIRTUAL_MACHINES: 'VirtualMachines',
  VIRTUAL_MACHINES_ALL: 'VirtualMachinesAll',
  VIRTUAL_MACHINES_DEMOS_INVENTORY: 'VirtualMachinesDemosInventory',
  VIRTUAL_MACHINES_NETWORK_INTERFACE_TYPES:
    'VirtualMachinesNetworkInterfaceTypes',
  VIRTUAL_MACHINES_TEMPLATES_INVENTORY: 'VirtualMachinesTemplatesInventory',
  VIRTUAL_MACHINE_TARGETS: 'VirtualMachine',
  VIRTUAL_MACHINE_TEMPLATE: 'templatedVm',
  VIRTUAL_MACHINE_TEMPLATES: 'templatedInventoryVms',
  VM_START_ORDER: 'VMStartOrder',
  VM_START_ORDER_ITEM: 'VMStartOrderItem',
  VM_STOP_ORDER: 'VMStopOrder',
  VM_STOP_ORDER_ITEM: 'VMStopOrderItem',
};

const TAGS_NOT_INVALIDATED_ON_PUSH = [
  TAGS.CURRENT_USER,
  TAGS.DATA_CENTERS,
  TAGS.TOPOLOGY_LIMITS,
];

export const TAGS_INVALIDATED_ON_PUSH = Object.values(TAGS).filter(
  (TAG) => TAGS_NOT_INVALIDATED_ON_PUSH.includes(TAG) === false,
);
