import { dcloudApi } from '../../../api';
import { TOPOLOGIES_PATH, HARDWARE_START_ORDER } from '../../../../api/paths';
import { setOriginal, setStartStopOrder } from '../slice';
import i18n from '../../../../i18n';
import { TAGS } from '../../../api/constants';

export const hardwareStartOrderApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getHardwareStartOrder: build.query<StartStopOrder, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setStartStopOrder(data));
          dispatch(setOriginal(data));
        } catch (err) {
          throw err;
        }
      },
      providesTags: [TAGS.HARDWARE_START_ORDER],
      query: (topologyUid) => ({
        errorMessage: i18n.t('startStopOrder.hardwareStart.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${HARDWARE_START_ORDER}`,
      }),
    }),
    getHardwareStartOrderItem: build.query<string, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.HARDWARE_START_ORDER_ITEM],
      query: (hardwareStartOrderUid) => ({
        errorMessage: i18n.t('startStopOrder.hardwareStart.fetch.errors.many'),
        method: 'get',
        url: `${HARDWARE_START_ORDER}/${hardwareStartOrderUid}`,
      }),
    }),
    updateHWStartOrder: build.mutation<VirtualMachine, StartStopOrderPayload>({
      invalidatesTags: [
        TAGS.HARDWARE_START_ORDER,
        TAGS.HARDWARE_START_ORDER_ITEM,
        TAGS.TOPOLOGY,
      ],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('startStopOrder.hardwareStart.update.error'),
        method: 'PUT',
        successMessage: i18n.t('startStopOrder.hardwareStart.update.success'),
        url: `${HARDWARE_START_ORDER}/${payload.uid}`,
      }),
    }),
  }),
});

export const {
  useGetHardwareStartOrderQuery,
  useGetHardwareStartOrderItemQuery,
  useUpdateHWStartOrderMutation,
} = hardwareStartOrderApi;
