import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

type FormsState = {
  isFormDirty: boolean;
};
export const initialState: FormsState = {
  isFormDirty: false,
};

const slice = createSlice({
  initialState,
  name: 'forms',
  reducers: {
    setIsFormDirty(state, { payload }: PayloadAction<boolean>) {
      state.isFormDirty = payload;
    },
  },
});

export const getForms = (state: RootState): FormsState => state[slice.name];

export const formsReducer = slice.reducer;

export const { setIsFormDirty } = slice.actions;

export const getIsFormDirty = createSelector(
  [getForms],
  ({ isFormDirty }) => isFormDirty,
);
