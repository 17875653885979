import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SelectOption } from 'dcloud-shared-ui';
import {
  ControlledCheckbox,
  ControlledTextfield,
} from '../../../../../../../../components';
import formStyles from '../../../../../EditFormStyles.module.css';
import { EvcModesDropDown } from '../../../../../../../components/EvcModesDropDown';
import styles from './AdvancedSettingsForm.module.css';

export interface AdvancedSettingsFormProps {
  hideDiagramFields?: boolean;
  evcModes: SelectOption<string>[];
}
export function AdvancedSettingsForm({
  hideDiagramFields,
  evcModes,
}: AdvancedSettingsFormProps): ReactElement {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  return (
    <div className={classNames(formStyles.sectionPadding, 'col-md-4')}>
      <ControlledTextfield
        control={control}
        error={errors?.advancedSettings?.nameInHypervisor?.message}
        label={t('virtualMachines.edit.advancedSettings.nameInHypervisor')}
        name="advancedSettings.nameInHypervisor"
        required={true}
        testId="edit-vm-advancedSettings-nameInHypervisor"
      />
      <ControlledTextfield
        control={control}
        error={errors?.advancedSettings?.biosUuid?.message}
        label={t('virtualMachines.edit.advancedSettings.biosUuid')}
        name="advancedSettings.biosUuid"
        required={false}
        testId="edit-vm-advancedSettings-biosUuid"
      />
      <EvcModesDropDown
        options={evcModes}
        defaultValue={control.getValues().advancedSettings?.evcMode}
      />
      <ControlledCheckbox
        className={styles.checkbox}
        control={control}
        id="advancedSettings.notStarted"
        label={t('virtualMachines.edit.advancedSettings.notStarted')}
        testId="edit-vm-remote-advancedSettings-notStarted-checkbox"
      />
      <ControlledCheckbox
        className={styles.checkbox}
        control={control}
        id="advancedSettings.allDisksNonPersistent"
        label={t('virtualMachines.edit.advancedSettings.allDisksNonPersistent')}
        testId="edit-vm-remote-advancedSettings-allDisksNonPersistent-checkbox"
      />
      {!hideDiagramFields && (
        <ControlledCheckbox
          className={styles.checkbox}
          control={control}
          id="advancedSettings.hiddenFromSession"
          label={t('virtualMachines.edit.advancedSettings.hiddenFromSession')}
          testId="edit-vm-remote-advancedSettings-hiddenFromSession-checkbox"
        />
      )}
    </div>
  );
}
