import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import {
  determineIsEditingAsset,
  determinePushAction,
} from '../../../../../entities/topologies/utils';
import { PushButton } from './components/PushButton';
import { PushInSync } from './components/PushInSync';
import { PushSpinner } from './components/PushSpinner';
import { PushProps } from './container';
import { useIsPushingAndRefreshingData } from './hooks/useIsPushingAndRefreshingData';

export const Push = ({
  isPushComplete,
  isFetchingTopology,
  isPushing,
  topology,
  onPush,
  onUpdate,
}: PushProps): ReactElement => {
  const isPushingAndRefreshingData = useIsPushingAndRefreshingData({
    isPushComplete,
    isPushing,
  });

  const { pathname } = useLocation();
  if (topology === undefined) return <></>;

  const PUSH = 'PUSH';
  const PUSH_DISABLED = 'PUSH_DISABLED';
  const PUSH_UPDATES = 'PUSH_UPDATES';
  const PUSH_UPDATES_DISABLED = 'PUSH_UPDATES_DISABLED';

  const action = determinePushAction(topology, undefined, undefined, true);
  const isEditingAsset = determineIsEditingAsset({ pathname });

  if (isPushingAndRefreshingData || isFetchingTopology) {
    return <PushSpinner />;
  }

  if (isEditingAsset) {
    switch (action) {
      case PUSH:
      case PUSH_DISABLED:
        return (
          <PushButton
            onClick={undefined}
            isDisabled={true}
            isEditingAsset={true}
          />
        );
      case PUSH_UPDATES:
      case PUSH_UPDATES_DISABLED:
        return (
          <PushButton
            onClick={undefined}
            isDisabled={true}
            isEditingAsset={true}
            isPushingUpdates={true}
          />
        );
      default:
        break;
    }
  }

  const handleOnPushClicked = () => onPush(topology);
  const handleOnPushUpdatesClicked = () => onUpdate(topology);

  switch (action) {
    case PUSH:
      return <PushButton onClick={handleOnPushClicked} />;
    case PUSH_DISABLED:
      return <PushButton onClick={undefined} isDisabled={true} />;
    case PUSH_UPDATES:
      return (
        <PushButton
          onClick={handleOnPushUpdatesClicked}
          isPushingUpdates={true}
        />
      );
    case PUSH_UPDATES_DISABLED:
      return (
        <PushButton
          onClick={undefined}
          isDisabled={true}
          isPushingUpdates={true}
        />
      );
    default:
      return <PushInSync />;
  }
};
