import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PageHeading } from '../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { startStopOrderSelectors } from '../../../../../redux/configuration/start-stop-order/slice';
import {
  useGetHardwareStartOrderItemQuery,
  useGetHardwareStartOrderQuery,
  useUpdateHWStartOrderMutation,
} from '../../../../../redux/configuration/start-stop-order/hardware-start/api';
import { StartStopOrderContent } from '../components/StartStopOrderContent';
import { HW_START_HELP_LINKS } from '../../../../../constants/user-guide';
import { FormUnsavedChangesPrompt } from '../../../../../components/FormUnsavedChangesPrompt';
import { useLeaveFormPrompt } from '../../../hooks/useLeaveFormPrompt';
import { HelpButton } from '../../../../../components/HelpButton';
import { HardwareStartTable } from './components/HardwareStartTable';

export function HardwareStart(): ReactElement {
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { data, isFetching, isError, refetch, isLoading } =
    useGetHardwareStartOrderQuery(topologyUid);
  const {
    isFetching: isFetchingItem,
    isError: isErrorFetchingItem,
    isLoading: isLoadingItem,
  } = useGetHardwareStartOrderItemQuery(data?.uid || '', {
    skip: data?.uid === undefined,
  });
  const [updateHWStartOrder, { isLoading: isUpdating }] =
    useUpdateHWStartOrderMutation();
  const isDirty = useSelector(startStopOrderSelectors.getStartStopOrderIsDirty);
  const startStopOrder = useSelector(startStopOrderSelectors.getStartStopOrder);

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  return (
    <section className="half-padding-left">
      <PageHeading
        ctaBtn={
          <PrimaryButton
            className="pull-right dbl-margin-top"
            testId="hw-start-order-submit"
            type="submit"
            colour="success"
            onClick={() => updateHWStartOrder(startStopOrder!)}
            disabled={!isDirty}
            loading={isUpdating}
          >
            {t('buttons.update')}
          </PrimaryButton>
        }
        pageTitle={t('startStopOrder.hardwareStart.name')}
      />
      {!isError && !isErrorFetchingItem && (
        <StartStopOrderContent
          isFetching={isFetching || isFetchingItem}
          isLoading={isLoading || isLoadingItem}
          buttonUnorderedLabel={t('buttons.unordered')}
          buttonOrderedLabel={t('buttons.ordered')}
          ordered={startStopOrder?.ordered}
          positions={startStopOrder?.positions}
          alertText={t('startStopOrder.hardwareStart.table.noResults')}
          table={HardwareStartTable}
          refetch={refetch}
        />
      )}
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <HelpButton links={HW_START_HELP_LINKS} />
    </section>
  );
}
