import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useGetTopologyQuery } from '../../redux/topologies/api';
import { FullScreenSpinner } from '../../components/Loaders/FullScreenSpinner';
import { EditTopology } from '.';

export function EditTopologyContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const {
    isLoading,
    isError,
    data: topology,
  } = useGetTopologyQuery(topologyUid);

  return !topology || isLoading || isError ? (
    <FullScreenSpinner />
  ) : (
    <EditTopology title={topology.name} />
  );
}
