import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  setIsPushModalVisible,
  setIsScheduleModalVisible,
  topologiesSelectors,
} from '../../../../../../../redux/topologies/slice';
import { RootState } from '../../../../../../../redux/store';
import {
  usePostDemoMutation,
  usePushToCustomContentMutation,
} from '../../../../../../../redux/configuration/demos/api';
import { topologiesApi } from '../../../../../../../redux/topologies/api';
import { ScheduleButton } from '.';

const mapDispatchToProps = {
  setIsPushModalVisible,
  setIsScheduleModalVisible,
};

const mapStateToProps = (state: RootState) => {
  const topology = topologiesSelectors.getTopologyToEdit(state);
  const topologyResult = topologiesApi.endpoints.getTopology.select(
    topology?.uid || '',
  )(state);

  return {
    isFetchingTopology: topologyResult.isLoading,
    isPushComplete: topologyResult.isSuccess,
    topology,
  };
};

const ScheduleButtonContainer = ({
  topology,
  setIsPushModalVisible,
  setIsScheduleModalVisible,
  isPushComplete,
}: ScheduleButtonContainerProps): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { isLoading: isPushingUpdates }] = usePushToCustomContentMutation({
    fixedCacheKey: 'shared-demo-put',
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, { isLoading: isPushing }] = usePostDemoMutation({
    fixedCacheKey: 'shared-demo-post',
  });

  const { isLoading: isLoadingTopology, isFetching: isFetchingTopology } =
    topologiesApi.endpoints.getTopology.useQueryState(topology?.uid || '');

  return (
    <ScheduleButton
      isPushComplete={isPushComplete}
      isFetchingTopology={isFetchingTopology || isLoadingTopology}
      isPushing={isPushingUpdates || isPushing}
      topology={topology}
      setIsPushModalVisible={setIsPushModalVisible}
      setIsScheduleModalVisible={setIsScheduleModalVisible}
    />
  );
};

export type ScheduleButtonContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type RtkScheduleButtonProps = {
  isPushing: boolean;
  isFetchingTopology: boolean;
};

export type ScheduleButtonProps = ScheduleButtonContainerProps &
  RtkScheduleButtonProps;

export const ConnectedScheduleButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleButtonContainer);
