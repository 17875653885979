import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { Mutex } from 'async-mutex';
import { api as instance } from '../../api';
import { API_ERROR_CODES } from '../../utils/constants';
import {
  createHeaders,
  dispatchErrorToast,
  dispatchSuccessToast,
  generateAuthHeaderValue,
  stripAndsetEtag,
} from '../utils';
import { handleAuthError, refreshAuthTokens } from '../auth/baseQueryAuth';
import { TAGS } from './constants';

const tagTypes = Object.values(TAGS);

const replayRequest = async (
  request: AxiosRequestConfig,
  api: BaseQueryApi,
  startAuthOnNotAuthorized: boolean,
  url: string,
  successMessage?: string,
  errorMessage?: string,
  suppressErrorToast?: boolean,
) => {
  try {
    const replayResult = await instance({
      ...request,
      headers: createHeaders(
        request.method,
        api,
        generateAuthHeaderValue(),
        url,
      ),
    });
    stripAndsetEtag(replayResult, api);
    successMessage && dispatchSuccessToast(successMessage, api);

    return {
      data:
        replayResult.data && '_embedded' in replayResult.data
          ? replayResult.data._embedded
          : replayResult.data,
    };
  } catch (replayAxiosError) {
    const replayError = replayAxiosError as AxiosError;
    handleAuthError(replayError, api, startAuthOnNotAuthorized);
    dispatchErrorToast(replayError, api, errorMessage, suppressErrorToast);

    return {
      error: {
        data: replayError.response?.data,
        status: replayError.response?.status,
      },
    };
  }
};

const mutex = new Mutex();
const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      overrideEtag?: string;
      data?: AxiosRequestConfig['data'];
      successMessage?: string;
      errorMessage?: string;
      suppressErrorToast?: boolean;
    },
    BaseQueryApi
  > =>
  async (
    {
      url,
      method,
      data,
      successMessage,
      errorMessage,
      overrideEtag,
      suppressErrorToast,
    },
    api,
  ) => {
    await mutex.waitForUnlock();
    const request = {
      data,
      headers: createHeaders(
        method,
        api,
        generateAuthHeaderValue(),
        url,
        overrideEtag,
      ),
      method,
      url: `${baseUrl}${url}`,
    };

    try {
      const result = await instance(request);
      stripAndsetEtag(result, api, url);
      successMessage && dispatchSuccessToast(successMessage, api);

      return {
        data:
          result.data && '_embedded' in result.data
            ? result.data._embedded
            : result.data,
      };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      if (error.response?.status === API_ERROR_CODES.NOT_AUTHORIZED) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            const refreshSuccess = await refreshAuthTokens(api);
            return refreshSuccess
              ? await replayRequest(
                  request,
                  api,
                  false,
                  url,
                  successMessage,
                  errorMessage,
                  suppressErrorToast,
                )
              : {
                  data: {},
                };
          } finally {
            release();
          }
        } else {
          await mutex.waitForUnlock();
          return await replayRequest(
            request,
            api,
            true,
            url,
            successMessage,
            errorMessage,
            suppressErrorToast,
          );
        }
      }
      dispatchErrorToast(error, api, errorMessage, suppressErrorToast);

      return {
        error: { data: error.response?.data, status: error.response?.status },
      };
    }
  };

export const dcloudApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || '',
  }),
  endpoints: () => ({}),
  reducerPath: 'dcloudApi',
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  tagTypes,
});
