import { connect } from 'react-redux';
import { RootState } from '../../../../../../../redux/store';
import {
  clearIsScheduleModalVisible,
  topologiesSelectors,
} from '../../../../../../../redux/topologies/slice';
import { getIsFormDirty } from '../../../../../../../redux/configuration/isFormDirty/slice';
import { ScheduleModal } from '.';

const mapDispatchToProps = {
  clearIsScheduleModalVisible,
};

const mapStateToProps = (state: RootState) => ({
  isFormDirty: getIsFormDirty(state),
  isScheduleModalVisible: topologiesSelectors.getIsScheduleModalVisible(state),
  topology: topologiesSelectors.getTopologyToEdit(state),
});

export const ScheduleModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleModal);

export type ScheduleModalProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
