import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

type DetermineEnabledScheduleButtonOnConfirm = {
  topology: Topology;
  setIsScheduleModalVisible: ActionCreatorWithPayload<Topology, string>;
  setIsPushModalVisible: ActionCreatorWithPayload<Topology, string>;
};

export const determineEnabledScheduleButtonOnConfirm = ({
  topology,
  setIsScheduleModalVisible,
  setIsPushModalVisible,
}: DetermineEnabledScheduleButtonOnConfirm): void => {
  if (topology.inSyncWithDcloud) {
    setIsScheduleModalVisible(topology);
  } else {
    setIsPushModalVisible(topology);
  }
};
