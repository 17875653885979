/* eslint @typescript-eslint/no-explicit-any: 0 */
import { SelectOption } from 'dcloud-shared-ui';
import classnames from 'classnames';
import React, { ChangeEvent, ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { Select } from '../../Select';
import { ControlledFieldProps } from '../types';
import styles from './ControlledSelect.module.css';
import { ControlledSelectSkeleton } from './ControlledSelectSkeleton';

export interface ControlledSelectProps<T extends Record<string, any>>
  extends ControlledFieldProps<T> {
  options: SelectOption<string>[];
  loading?: boolean;
  labelClass?: string;
  testId?: string;
  id?: string;
  customOnChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  formGroupTextClass?: string;
}

export function ControlledSelect<T extends Record<string, any>>({
  className,
  control,
  defaultValue = '',
  error,
  horizontal = false,
  label,
  labelClass = '',
  loading = false,
  name,
  options,
  required = false,
  testId,
  id,
  customOnChange,
  formGroupTextClass,
}: ControlledSelectProps<T>): ReactElement {
  if (loading) {
    return <ControlledSelectSkeleton />;
  }

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ name: fieldName, onChange, onBlur, value }) => (
        <Select
          label={label}
          labelClass={classnames(labelClass, 'half-margin-bottom')}
          errors={error ? [error] : undefined}
          name={fieldName}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            customOnChange && customOnChange(e);
            onChange(e.target.value);
          }}
          onBlur={onBlur}
          value={value}
          required={required}
          options={options}
          horizontal={horizontal}
          formGroupTextClass={formGroupTextClass}
          data-testid={testId}
          className={classnames(styles.select, className)}
          id={id}
        />
      )}
    />
  );
}
