import React, { ReactElement } from 'react';
import { Modal } from '../../../../components';
import { CloneTopologyForm } from './CloneTopologyForm';
import { CloneTopologyModalProps } from './container';
import { determineTopologyToCloneModalTitle } from './utils/utils';

export function CloneTopologyModal({
  clearTopologyToClone,
  cloneTopology,
  isCloning,
  topologyToClone,
  isConfigurationPage,
}: CloneTopologyModalProps): ReactElement {
  return (
    <Modal
      testId="topology-clone-modal"
      title={determineTopologyToCloneModalTitle(isConfigurationPage)}
      show={!!topologyToClone}
      size="default"
      onClose={clearTopologyToClone}
      clickScreenToClose={true}
    >
      {topologyToClone && (
        <CloneTopologyForm
          isConfigurationPage={isConfigurationPage}
          topologyToClone={topologyToClone}
          isCloning={isCloning}
          onClone={cloneTopology}
          onClose={clearTopologyToClone}
        />
      )}
    </Modal>
  );
}
