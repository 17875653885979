import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'dcloud-shared-ui';
import { ControlledSelect } from '../../../components';

export type EvcModesDropDownProps = {
  options: SelectOption<string>[];
  defaultValue: string;
};

export const EvcModesDropDown = ({
  options,
  defaultValue,
}: EvcModesDropDownProps): ReactElement => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <ControlledSelect
      control={control}
      options={options}
      defaultValue={defaultValue}
      name="advancedSettings.evcMode"
      label={t('virtualMachines.edit.advancedSettings.evcMode')}
    />
  );
};
