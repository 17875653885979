import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import {
  setTopologyToSave,
  setTopologyToUpdate,
  topologiesSelectors,
} from '../../../../../redux/topologies/slice';
import {
  usePostDemoMutation,
  usePushToCustomContentMutation,
} from '../../../../../redux/configuration/demos/api';
import { topologiesApi } from '../../../../../redux/topologies/api';
import { Push } from '.';

const mapStateToProps = (state: RootState) => {
  const topology = topologiesSelectors.getTopologyToEdit(state);
  const topologyResult = topologiesApi.endpoints.getTopology.select(
    topology?.uid || '',
  )(state);

  return {
    isFetchingTopology: topologyResult.isLoading,
    isPushComplete: topologyResult.isSuccess,
    topology,
  };
};

const mapDispatchToProps = {
  onPush: setTopologyToSave,
  onUpdate: setTopologyToUpdate,
};

const PushWrapperContainer = ({
  topology,
  onPush,
  onUpdate,
  isPushComplete,
}: PushContainerProps): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { isLoading: isPushingUpdates }] = usePushToCustomContentMutation({
    fixedCacheKey: 'shared-demo-put',
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, { isLoading: isPushing }] = usePostDemoMutation({
    fixedCacheKey: 'shared-demo-post',
  });

  const { isLoading: isLoadingTopology, isFetching: isFetchingTopology } =
    topologiesApi.endpoints.getTopology.useQueryState(topology?.uid || '');

  return (
    <Push
      isPushComplete={isPushComplete}
      isFetchingTopology={isFetchingTopology || isLoadingTopology}
      isPushing={isPushingUpdates || isPushing}
      topology={topology}
      onPush={onPush}
      onUpdate={onUpdate}
    />
  );
};

type PushContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type RtkPushProps = {
  isPushing: boolean;
  isFetchingTopology: boolean;
};

export type PushProps = PushContainerProps & RtkPushProps;

export const ConnectedPush = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushWrapperContainer);
