import { MAX_TOPOLOGY_REMOTE_ACCESS_COUNT } from '../pages/EditTopology/configuration/components/VirtualMachineEntities/constants';

const buttons = {
  add: 'Add',
  back: 'Back',
  cancel: 'Cancel',
  changeIcon: 'Change Icon',
  close: 'Close',
  concurrent: 'Concurrent',
  confirm: 'Confirm',
  continue: 'Continue',
  create: 'Create',
  delete: 'Delete',
  discardChangesAndPush: 'Discard changes and Push',
  discardChangesAndSave: 'Discard changes and Save',
  new: 'New',
  ordered: 'Ordered',
  push: 'Push',
  refresh: 'Refresh',
  requestAccess: 'Request Access',
  save: 'Save',
  schedule: 'Schedule',
  scheduleWithoutChanges: 'Schedule without changes',
  seeFullDetails: 'See Full Details',
  select: 'Select',
  selectAnIcon: 'Select an icon',
  sequential: 'Sequential',
  submit: 'Submit',
  unordered: 'Unordered',
  update: 'Update',
  use: 'Use',
};

const common = {
  activeTemplate: 'Active Template',
  close: 'Close',
  dataCenter: 'Data Center',
  deleting: 'Deleting ...',
  demo: 'Demo',
  demoId: 'Demo ID',
  demoPromoted: 'Promoted',
  demoPublished: 'Published',
  description: 'Description',
  details: 'Details',
  error: 'Error',
  errorMessage: 'There was an error, please try again.',
  home: 'Home',
  lastUpdated: 'Last Updated',
  name: 'Name',
  no: 'No',
  none: 'None',
  pleaseSelect: 'Please select',
  pleaseWait: 'Please wait',
  quantity: 'Quantity',
  search: 'Search',
  somethingWentWrong: 'Sorry, something went wrong',
  status: 'Status',
  success: 'Success',
  topologies: 'Topologies',
  updating: 'Updating ...',
  warning: 'Warning',
  yes: 'Yes',
};

const currentUser = {
  fetch: {
    error: 'There was an error fetching user information, please try again.',
  },
};

const telephony = {
  addError: 'Unable to enable PSTN services, please try again.',
  addSuccess: 'PSTN services enabled successfully.',
  alertText: 'PSTN services are not supported for this data center',
  checkboxLabel: 'Enable PSTN Services',
  deleteError: 'Unable to disable PSTN services, please try again.',
  deleteSuccess: 'PSTN services removed successfully.',
  fetchError:
    'There was an error fetching telephony information, please try again.',
  inventoryFetchError:
    'There was an error fetching inventory telephony information, please try again.',
  name: 'Telephony',
};

const dataCenters = {
  fetch: {
    error: 'There was an error fetching data centers, please try again.',
  },
};

const demos = {
  fetch: {
    errors: {
      many: 'There was an error fetching demos, please try again.',
    },
  },
  name: 'Demo',
  table: {
    headings: {
      id: 'ID',
      name: 'Name',
      publishedOn: 'Publish Date',
    },
  },
};

const diagram = {
  add: {
    error:
      'There was an error performing create action, please try again or contact support for a resolution.',
  },
  anyconnect: 'AnyConnect',
  delete: {
    error:
      'There was an error performing delete action, please try again or contact support for a resolution.',
  },
  endpointKit: 'Endpoint Kit',
  fetch: {
    error: 'There was an error fetching the diagram, please try again.',
  },
  hideGrid: 'Hide grid',
  modals: {
    addIcon: {
      form: {
        buttons: {
          labels: {
            addAnotherLink: 'Add another Link',
            addAnotherProperty: 'Add another Property',
            addLink: 'Add a Link',
            addProperty: 'Add a Property',
          },
        },
        errors: {
          displayText: {
            maxLength: 'Enter a Display text with 255 characters or less',
            required: 'Enter a Display text',
          },
          key: {
            maxLength: 'Enter a Property with 255 characters or less',
            required: 'Enter a Property',
          },
          name: {
            maxLength: 'Enter a Name with 255 characters or less',
            required: 'Enter a Name',
          },
          tabs: {
            tabHasError: 'Please check and fix the error in this tab',
          },
          value: {
            invalidLink:
              'Please enter a valid URL beginning with https:// or http://',
            maxLength: 'Enter a Value with 255 characters or less',
          },
        },
        labels: {
          displayText: 'Display Text *',
          key: 'Property *',
          linkAddress: 'Link Address *',
          name: 'Name',
          value: 'Value',
        },
        messages: {
          infoPt0_0:
            'This text will be accessible in the session view. You can find it in the sidebar after clicking the icon.',
          infoPt0_1: 'Example: (Property) IP Address (Value) 10.1.1.1',
          infoPt1_0:
            'This is a clickable link that will be accessible in the session view. You can find it in the sidebar after clicking the icon.',
          infoPt1_1: '',
        },
        placeHolders: {
          enterDisplayText: 'Enter Display Text',
          enterLinkAddress: 'Enter Link Address',
          enterName: 'Enter Name',
          enterProperty: 'Enter Property',
          enterValue: 'Enter Value',
        },
        tabs: {
          links: {
            title: 'Links',
          },
          properties: {
            title: 'Properties',
          },
        },
      },
      title: 'Add Icon',
    },
    configureDevice: {
      loading: {
        text: 'Loading Hardware configuration options, please wait ...',
      },
    },
    configureNetwork: {
      loading: {
        text: 'Loading Network configuration options, please wait ...',
      },
    },
    configureVm: {
      loading: {
        text: 'Loading Virtual Machine configuration options, please wait ...',
      },
    },
    deleteIcon: {
      heading: 'Delete :name Icon?',
      subHeading: 'Are you sure you want to delete the icon?',
    },
    editIcon: {
      title: 'Edit Icon',
    },
    logicalConnectionMaxLimitReached: {
      heading: 'Maximum line limit reached (20 lines)',
      subHeading:
        "To add more connections, consider using the alternative 'draw line' feature. Note: Lines drawn this way won't stick to objects when moved",
    },
  },
  showGrid: 'Show grid',
  sideMenu: {
    addBox: {
      label: 'Add a Box',
    },
    addHardware: {
      label: 'Add Hardware',
      modals: {
        loadingHardware: {
          text: 'Loading hardware options, please wait ...',
        },
        savingHardware: {
          text: 'Saving hardware items please wait ...',
        },
      },
    },
    addIcon: {
      label: 'Add Icon',
    },
    addLine: {
      label: 'Add a Line',
    },
    addNetwork: {
      label: 'Add Network',
      modals: {
        loadingNetworks: {
          text: 'Loading network options, please wait ...',
        },
        maxLimitReached: {
          heading: 'Networks limit reached',
          subHeading:
            'Unable to add a network as the maximum number of networks for a topology has been reached',
        },
      },
    },
    addText: {
      forms: {
        edit: {
          inputs: {
            rotate: {
              title: 'Rotate Text',
            },
            text: {
              errors: {
                required: 'Please enter text.',
              },
              label: 'Text',
            },
          },
        },
      },
      label: 'Add Text',
      value: 'Text',
    },
    addVm: {
      label: 'Add VM',
      modals: {
        loadingVm: {
          text: 'Loading VM options, please wait ...',
        },
        savingVm: {
          text: 'Saving VM please wait ...',
        },
      },
    },
    drawLine: {
      label: 'Draw a Line',
    },
    drawLogicalConnection: {
      label: 'Draw a Line between two nodes',
    },
    snapToGrid: {
      disable: 'Disable auto align',
      enable: 'Enable auto align',
    },
  },
  topMenu: {
    defaultDrawingColorTitle:
      'Use white color in dark theme and gray color in light theme',
  },
  update: {
    error:
      'There was an error updating the diagram, please try again or contact support for a resolution.',
  },
};

const footer = {
  contacts: 'Contacts',
  cookiePolicy: 'Cookie Policy',
  feedback: 'Feedback',
  help: 'Help',
  privacyStatement: 'Privacy Statement',
  siteMap: 'Site Map',
  termsAndConditions: 'Terms & Conditions',
  trademarks: 'Trademarks',
};

const icons = {
  accessPoint: 'Access Point',
  cloud: 'Cloud',
  firewall: 'Firewall',
  genericBuilding: 'Generic Building',
  laptop: 'Laptop',
  publicCloud: 'Public Cloud',
  router: 'Router',
  saasConnect: 'SaaS Connect',
  server: 'Server',
  storage: 'Storage',
  switch: 'Switch',
  telephone: 'Telephone',
  wlc: 'WLC',
};

const topologies = {
  activeTemplate: 'Active Template',
  clone: {
    cloneButtonLabel: 'Clone',
    clonedTopologyLinkLabel: 'View new topology',
    error: 'There was an error cloning the topology, please try again later',
    form: {
      description: {
        label: 'Description',
        validation: {
          required: 'Please enter a Description',
        },
      },
      name: {
        label: 'Name',
        validation: {
          maxLength: 'Enter a Name with 255 characters or less',
          required: 'Please enter a Name',
        },
      },
    },
    heading: 'Create A Copy',
    headingForConfigurationPage: 'Clone Topology',
    success: 'Topology cloned successfully.',
  },
  configurationEditWarnings: {
    anActiveTemplate: 'an Active Template',
    and: 'and',
    promoted: 'Promoted',
    published: 'Published',
    warningEnd: ', any changes could affect other dCloud users',
    warningStart: 'Warning! This Topology is ',
  },
  delete: {
    confirmPrompt: 'Type "delete" to continue',
    confirmWarning:
      'Deleting this topology will delete any associated demos in dCloud',
    error: 'There was an error deleting the topology, please try again later',
    heading: 'Delete :topology?',
    subHeading: 'This action cannot be undone.',
    success: 'Topology deleted successfully.',
  },
  error: {
    heading: 'Oops',
    subHeading: 'There was an error getting your topologies, please try again',
  },
  fetch: {
    error: 'There was an error ',
    errors: {
      many: 'There was an error getting your topologies, please try again',
    },
  },
  filter: {
    empty: 'No Topology matched your search or filter selection.',
  },
  filters: {
    applied: 'applied',
    applying: 'applying ... ',
    dataCenters: {
      ANZ: 'SYD (ANZ)',
      APJ: 'SNG (APJ)',
      EMEA: 'LON (EMEA)',
      USEast: 'RTP (US East)',
      USWest: 'SJC (US West)',
      title: 'Data Centers',
    },
    heading: 'Filters',
    show: {
      activeTemplates: 'Active Templates',
      myCustomContent: 'My Custom Content',
      published: 'Published',
      sharedWithMe: 'Shared with me',
      title: 'Show',
    },
  },
  noTopologies: {
    heading: 'Start Building Demos',
    subHeading: 'Use our Wizard to create your first Topology',
  },
  owner: 'Owner',
  pleaseEnterThreeCharacters: 'Please enter at least 3 characters',
  promoted: 'Promoted',
  push: {
    buttonText: 'Save to Custom Content',
    disabledButtonTooltip:
      'You must be the owner of the topology to push to custom content',
    disabledButtonTooltipWhenEditingAsset: 'Push is disabled whilst editing',
    error: 'There was an error saving the topology, please try again.',
    inSyncWithDcloud: 'In Sync with dCloud',
    modalHeading: 'Save to Custom Content?',
    modalSubheading:
      'Are you sure you want to save :topology to your custom content in dCloud?',
    modalSubheadingWithWarning:
      'Warning: Unsaved changes will be discarded. Please save changes on this page if needed before pushing to dCloud.',
    pushButtonText: 'Push to Custom Content',
    pushModalHeading: 'Push to Custom Content?',
    pushUpdatesButtonText: 'Push updates to Custom Content',
    success: 'Topology saved successfully.',
  },
  schedule: {
    inSync: {
      confirmationButtonLabel: 'Schedule',
      heading: 'This content has been pushed to dCloud',
      subHeading: 'Do you want to schedule?',
    },
    modalSubheadingWithWarning:
      'Warning: Unsaved changes will be not be scheduled. Please save changes on this page if needed before scheduling.',
    notInSync: {
      confirmationButtonLabel: 'Push and Schedule',
      heading: 'This content has not been pushed to dCloud',
      subHeading: 'Do you want to push to dCloud and schedule?',
    },
    notOwnerTooltip:
      "Schedule disabled whilst editing or if you're not the owner of the topology",
  },
  sharedWithMeTooltipText: 'Shared',
  title: 'My Topologies',
  topologyLimitsToolbar: {
    fetch: {
      error:
        'There was an error fetching topology limits, please try again or contact support for a resolution',
    },
    labels: {
      cpus: 'vCPUs',
      devices: 'Devices',
      memoryGB: 'Memory (GB)',
      natRules: 'Public NAT Rules',
    },
    modal: {
      message:
        'You have gone over the approved limit for :label and this demo will need to be approved by dCloud support to be scheduled.',
    },
  },
  topologyLimitsWarningModal: {
    message:
      'You are about to go over the approved limit for :label and this demo will need to be approved by dCloud support to be scheduled.',
  },
  updatePush: {
    buttonText: 'Push to Custom Content',
    disabledButtonTooltip:
      'You must be the owner of the topology to push updates to custom content',
    disabledButtonTooltipWhenEditingAsset: 'Push is disabled whilst editing',
    error: 'There was an error updating the topology, please try again.',
    modalHeading: 'Push to Custom Content?',
    modalSubheading:
      'Are you sure you want to push :topology to your custom content in dCloud?',
    pushButtonText: 'Push to Custom Content',
    pushUpdatesButtonText: 'Push updates to Custom Content',
    success: 'Topology updated successfully.',
  },
};

const configuration = {
  header: {
    buttons: {
      config: 'Config',
      diagram: 'Diagram',
    },
    links: {
      home: 'Home',
      topologies: 'Topologies',
    },
  },
};

const createTopology = {
  demo: {
    blank: {
      subTitle: 'Create from scratch',
      title: 'Blank',
    },
    import: {
      demos: {
        id: 'ID',
        name: 'Name',
        publishDate: 'Publish Date',
      },
      subTitle: 'Import TBv2 Content',
      title: 'Import',
    },
    template: {
      demoId: 'Demo ID',
      description: 'Description',
      subTitle: 'Start with a Topology Template',
      title: 'Template',
    },
  },
  error: 'There was an error creating the topology, please try again',
  success: 'The topology was created successfully',
};

const home = {
  carousel: {
    default: {
      button: 'Get Started',
      heading: 'Introducing dCloud Topology Builder',
      subHeading: 'Bring your ideas to life with dCloud',
    },
    webex: {
      button: 'Join',
      heading: 'Join the Topology Builder Community Webex Space',
      subHeading:
        'Ask Questions and Collaborate with the growing Topology Builder Community.',
    },
  },
  documentation: {
    description:
      'Our detailed technical documentation explains everything you need to know.',
    title: 'Documentation',
  },
  integrations: {
    description: 'Add and Edit Integration',
    title: 'Integration Manager',
  },
  myTopologies: {
    description:
      'Create, edit and manage your content in one place. User friendly, fast and powerful.',
    title: 'My Topologies',
  },
  requestAFeature: {
    description:
      'Submit a request for a new feature to the product development team and user experience team.',
    title: 'Request a Feature',
  },
  templates: {
    description: 'Edit VM and Topology Templates',
    title: 'Template Manager',
  },
  videos: {
    description: {
      becomeAnExpert: 'Become an expert',
      in: 'in',
      learn: 'Learn',
      or: 'or',
      suffix:
        'in dCloud Topology builder with our series of step-by-step tutorials.',
      whatsNew: "What's New",
    },
    title: 'Videos',
  },
};

const hardware = {
  add: {
    error:
      'There was an error adding one or more item(s) of hardware, please try again.',
    success: 'Hardware added successfully.',
    title: 'Add Hardware',
  },
  delete: {
    error: 'There was an error deleting the hardware, please try again.',
    heading: 'Delete :hardware',
    subHeading: 'Are you sure you want to delete this item?',
    success: 'Hardware deleted successfully.',
  },
  edit: {
    enableHardwareConsole: 'Enable Hardware Console',
    enablePowerControl: 'Enable Power Control',
    error: {
      fetchScripts:
        'There was an error fetching inventory scripts, please try again.',
      update: 'There was an error updating the hardware, please try again.',
    },
    hiddenFromSession: 'Hide Hardware from running sessions',
    inventoryNameLabel: 'Inventory Name',
    nameLabel: 'Name',
    nameMaxLengthError: 'Enter a Name with 255 characters or less',
    nameRequiredError: 'Please provide a unique name',
    networkingInterface: {
      delete: {
        heading: 'Delete',
        subHeading: 'Are you sure you want to delete this item?',
      },
      table: {
        interface: 'Interface',
        network: 'Network',
      },
    },
    success: 'Hardware successfully edited',
    titles: {
      general: 'General',
      networking: 'Networking',
      page: 'Edit Hardware',
      scripts: 'Scripts',
      templates: 'Template Config',
    },
    uniqueNameError: 'Please provide a unique name. This one is taken.',
  },
  empty: {
    alertText: 'No Hardware',
  },
  fetch: {
    error: 'There was an error fetching hardware, please try again.',
  },
  name: 'Hardware',
  physicalHardware: {
    add: {
      title: 'Add Hardware',
    },
    edit: {
      title: 'Edit Hardware',
    },
    name: 'Hardware',
    requestHardware: 'Request Hardware',
  },
  table: {
    headings: {
      hardwareConsoleEnabled: 'HW Console Available',
      interfaces: 'Interfaces',
      name: 'Name',
      powerControlEnabled: 'Power Control Available',
    },
  },
  virtualHardware: {
    add: {
      error:
        'There was an error creating the virtual hardware, please try again.',
      success: 'Virtual hardware added successfully',
      title: 'Add Virtual Hardware',
    },
    delete: {
      error:
        'There was an error deleting the virtual hardware, please try again.',
    },
    edit: {
      error:
        'There was an error updating the virtual hardware, please try again.',
      success: 'Virtual hardware updating successfully',
      title: 'Edit Virtual Hardware',
    },
    empty: {
      alertText: 'No Virtual Hardware',
    },
    fetch: {
      errors: {
        many: 'There was an error fetching virtual hardware, please try again.',
      },
    },
    name: 'Virtual Hardware',
    requestHardware: 'Request Virtual Hardware',
  },
};

const helpButtonLinks = {
  dCloudSupport: 'dCloud Support',
  demoCreationHelp: 'Demo Creation Help',
  userGuide: 'User Guide',
};

const videos = {
  fetchError: 'There was an error fetching videos, please try again.',
  header: { title: 'Getting Started Guide' },
  updated: 'Updated',
};

const virtualMachines = {
  add: {
    duplicateIp: 'This IP Address is already in use on this network',
    duplicateMac: 'This MAC Address is already in use on this network',
    error: 'There was an error creating the virtual machine, please try again.',
    ipRange: 'The IP must be in the correct range for this network',
    success: 'Virtual machine added successfully',
    tabTitles: {
      demoTemplates: 'Demo Templates',
      vmTemplates: 'VM Templates',
    },
    title: 'Add Virtual Machine',
  },
  configure: {
    select: {
      limitErrorPt1: 'Network connection limit of',
      limitErrorPt2: 'exceeded',
    },
    table: {
      headings: {
        assignDhcp: 'Assign via DHCP',
        ipAddress: 'IP address',
        macAddress: 'MAC address',
        name: 'NIC Name',
        network: 'Network',
      },
    },
    title: 'Configure Network',
  },
  delete: {
    error: 'There was an error deleting the virtual machine, please try again.',
    heading: 'Delete :virtualMachine',
    subHeading: 'Are you sure you want to delete this item?',
    success: 'Virtual machine deleted successfully.',
  },
  edit: {
    advancedSettings: {
      allDisksNonPersistent: 'All Disks are Non-Persistent',
      biosUuid: 'BIOS UUID',
      evcMode: 'EVC Mode',
      hiddenFromSession: 'Hide VM from running sessions',
      nameInHypervisor: 'VM Name',
      notStarted: 'Do Not Start Virtual Machine',
    },
    changeIcon: 'Change Icon',
    command: 'Command to Execute',
    cpuQty: 'vCPUs',
    delaySecs: 'Delay (sec)',
    delayShutdownSecs: 'Delay shutdown (Max 600 seconds)',
    description: 'Description',
    dhcpConfig: {
      labels: {
        defaultGateway: 'Default Gateway',
        primaryDnsIp: 'Primary DNS Server',
        secondaryDnsIp: 'Secondary DNS Server',
      },
    },
    enableGuestAutomation: 'Enable Startup Automation',
    enableShutdownAutomation: 'Enable Shutdown Automation',
    error: 'There was an error updating the virtual machine, please try again.',
    formErrors: {
      advancedSettings: {
        biosUuid: {
          patternMatch:
            'Enter a BIOS UUID in the format of 16 hexadecimal pairs, each pair separated by a space, except for pairs 8 and 9 which must be separated by a hyphen, for example "22 29 5e 14 9a a9 98 04-62 ff a4 ae 0e 4c 55 d3"',
          required: 'Please enter a BIOS UUID',
        },
        nameInHypervisor: {
          allowedCharsContains:
            'Please enter a VM name containing alphanumeric characters, underscores, dots and hyphens',
          allowedCharsStartWith:
            'Please enter a VM Name starting with an alphanumeric character',
          maxLength: 'Enter a VM Name with 255 characters or less',
          required: 'Please enter a VM Name',
        },
      },
      commandMaxLength: 'Enter a Command with 255 characters or less',
      commandRequired: 'Please enter a command',
      commandValidation:
        'Enter a Command with no trailing or leading whitespace',
      cpuQty: 'vCPUs must be between 1 and 96',
      defaultGatewayRequired: 'Gateway is required with DHCP',
      delayIncrementsOf5: 'Please enter a value in increments of 5',
      delayMax: 'Please enter a maximum value of 7200',
      delayMin: 'Please enter a minimum value of 0',
      delayShutdownMax: 'Please enter a maximum value of 600',
      delayShutdownMin: 'Please enter a minimum value of 30',
      delayTypeError: 'Please enter a number',
      descriptionMaxLength: 'Enter a Description with 512 characters or less',
      descriptionRequired: 'Please enter a Description',
      dhcpEnding: 'IP Addresses ending in 0, 1 & 255 cannot be used with DHCP',
      dhcpRequired: 'An IP Address is required with DHCP',
      dhcpReserved: 'IP Address reserved by Infrastructure',
      dhcpSubnet: 'IP address is not within the subnet range of ',
      internalUrls: {
        description: {
          emptyUrlWarning: 'Please enter a description',
          maxLength: 'Enter a Description with 255 characters or less',
        },
        location: {
          emptyDescriptionWarning:
            'Please enter a URL or remove the associated description',
          url: 'Please enter a valid URL',
        },
      },
      memoryGbLimits: 'Memory must be between 1 and 512 GB',
      memoryMbLimits: 'Memory must be between 4 and 524,288 MB',
      memoryMbMultipleOf4: 'Memory must be a multiple of 4',
      nameMaxLength: 'Enter a Name with 255 characters or less',
      nameRequired: 'Please enter a Name',
      nameUnique: 'Please provide a unique name. This one is taken.',
      networking: {
        ipAddress: {
          gwMatch: 'This IP address is already in use by the Default GW',
          patternMatch: 'Please enter a valid IP Address',
        },
        macAddress: {
          gwMatch: 'This MAC address is already in use by the Default GW',
          patternMatch: 'Please enter a valid MAC Address',
          prefixPatternMatch: 'The MAC Address must begin with 00:50:56',
        },
        networkName: 'Please enter a Network Name',
        type: 'Please choose a NIC Type',
      },
      remoteAccess: {
        adapter: 'Please choose an adapter',
        displayCredentials: {
          passwordMaxLength: 'Enter a Password with 255 characters or less',
          usernameMaxLength: 'Enter a Username with 255 characters or less',
        },
        passwordMaxLength: 'Enter a Password with 255 characters or less',
        usernameMaxLength: 'Enter a Username with 255 characters or less',
      },
    },
    largeVmWarningPt1: 'You have configured a large amount of',
    largeVmWarningPt2:
      '. Please only configure the amount needed to run the topology.',
    memoryAndCpu: 'Memory/vCPUs',
    memoryMb: 'Memory',
    name: 'Name',
    nestedHypervisor: 'Nested Hypervisor',
    networkInterfaceTypes: {
      error:
        'There was an error fetching network interface types, please try again.',
    },
    networking: {
      adapterName: 'Adapter Name',
      confirmDelete: {
        heading: 'Warning',
        rdpEnabledSubheading:
          'This NIC has an RDP configured. This can be found in the Remote access section below. Deleting this NIC interface will also delete RDP Configuration associated with it. Please confirm you wish to delete.',
        subheading:
          'This NIC has one or more rules associated with it. These can be found in the Traffic section. Deleting this NIC interface will also delete all rules associated with it. Please confirm you wish to delete.',
      },
      dhcp: {
        title: 'Assign via DHCP',
      },
      empty: {
        alertText: 'No Network Interfaces',
      },
      ipAddress: 'IP Address',
      macAddress: 'MAC Address',
      maxLimit: 'You cannot add more than 10 NICs to a vm',
      maxLimitInterfacesForNetworkPart1: 'This network currently has',
      maxLimitInterfacesForNetworkPart2:
        'connections which is the maximum amount allowed',
      minLimit: 'Must have at least one NIC attached to your vm',
      network: 'Network',
      type: 'NIC Type',
      warnings: {
        changesInfo:
          'Changes made here are not configured in the OS. Please use the VM Console to configure your networking to match these settings',
        duplicateIp: 'Warning! There is a duplicate IP Address configured',
        duplicateMac: 'Warning! There is a duplicate MAC Address configured',
      },
    },
    osIcon: 'Operating System Icon',
    remoteAccess: {
      adapterToConnect: 'Adapter to connect',
      autoLogin: 'Enable automatic Login',
      displayCredentials: {
        enableDisplayDifferentCredentials:
          'Display different username and password to user',
        password: 'Display Password',
        username: 'Display Username',
      },
      internalUrls: {
        description1: 'Description 1',
        description2: 'Description 2',
        url1: 'URL 1',
        url2: 'URL 2',
      },
      noValidAdaptersMessage:
        'Web RDP and SSH Access are disabled as no valid adapters are available. Please use the Networking section to add a network interface with an IP Address',
      password: 'Password',
      sshAccess: 'SSH Access',
      username: 'Username',
      vmConsole: 'VM Console',
      webRDP: 'Web RDP',
    },
    sectionTitles: {
      advancedSettings: 'Advanced Settings',
      general: 'Virtual Machine',
      guestAutomation: 'Guest Automation',
      networking: 'Networking',
      remoteAccess: 'Remote Access',
    },
    sessionXmlMessage:
      'Session.xml file will be deposited in the dcloud folder of the VM root drive',
    success: 'Virtual machine updated successfully',
    title: 'Edit Virtual Machine',
  },
  empty: {
    alertText: 'No Virtual Machines',
  },
  fetch: {
    errors: {
      many: 'There was an error fetching virtual machines, please try again.',
      one: 'There was an error fetching the virtual machine, please try again.',
    },
  },
  name: 'Virtual Machines',
  remoteAccessCountWarning: {
    adding: {
      modal: {
        heading: 'Sorry, you cannot add this VM',
        subHeading: `This topology has already reached the maximum remote access (RDP/SSH) count for a topology (${MAX_TOPOLOGY_REMOTE_ACCESS_COUNT}) and this VM you are trying to add also has RDP and/or SSH enabled. To add the VM, please remove the remote access setting on an existing VM and try again`,
      },
    },
    editing: {
      rdp: `RDP cannot be enabled because the maximum remote access (RDP/SSH) count for a topology (${MAX_TOPOLOGY_REMOTE_ACCESS_COUNT}) has been reached`,
      ssh: `SSH cannot be enabled because the maximum remote access (RDP/SSH) count for a topology (${MAX_TOPOLOGY_REMOTE_ACCESS_COUNT}) has been reached`,
    },
  },
  requestVm: 'Request Virtual Machine',
  table: {
    headings: {
      cpuQty: 'CPUs',
      memoryGb: 'Memory (GB)',
      name: 'Name',
      nicQty: 'NICs',
    },
  },
  tabs: {
    demo: 'Demo',
    template: 'Template',
  },
};

const inventoryTopologies = {
  fetch: {
    errors: {
      many: 'There was an error fetching inventory topologies, please try again or contact support',
    },
  },
  infoModal: {
    headings: {
      architecture: 'Architecture',
      datacenter: 'Datacenter',
      demoId: 'Demo ID',
      description: 'Description',
      lastUpdated: 'Last Updated',
      originalName: 'Original Name',
      topologyUid: 'Topology ID',
    },
  },
  table: {
    headings: {
      architecture: 'Architecture',
      demoId: 'Demo ID',
      description: 'Description',
      name: 'Name',
    },
  },
  vms: {
    table: {
      headings: {
        cpus: 'CPUs',
        description: 'Description',
        memoryGb: 'Memory (GB)',
        name: 'Name',
        nics: 'NICs',
      },
      noResultsAlert:
        'There are no VMs associated with this topology to display',
    },
  },
};

const inventoryVirtualMachines = {
  fetch: {
    errors: {
      many: 'There was an error fetching virtual machines, please try again.',
    },
  },
  infoModal: {
    headings: {
      architecture: 'Architecture',
      autoLogin: 'Auto Login',
      cpu: 'CPU',
      datacenter: 'Datacenter',
      lastUpdated: 'Last Updated',
      licensed: 'Licensed',
      managementIp: 'Management IP',
      memory: 'Memory',
      originalName: 'Original Name',
      password: 'Password',
      rdpEnabled: 'RDP Enabled',
      sshEnabled: 'SSH Enabled',
      username: 'Username',
      version: 'Version',
      vmId: 'VM ID',
      vmName: 'Original Name',
    },
  },
  name: 'Template',
  table: {
    headings: {
      architecture: 'Architecture',
      autoLogin: 'AutoLogin',
      cpuQty: 'CPUs',
      description: 'Description',
      licenced: 'licensed',
      managementIp: 'Management IP',
      memoryGb: 'Memory (GB)',
      name: 'Name',
      networkInterfaces: 'NICs',
      nics: 'NICs',
      password: 'Password',
      rdpEnabled: 'RDP Enabled',
      sshEnabled: 'SSH Enabled',
      username: 'Username',
    },
  },
};

const modals = {
  formUnsavedChanges: {
    cancelButtonLabel: 'Stay',
    confirmButtonLabel: 'Leave',
    heading: 'You have unsaved changes',
    subHeading:
      'If you leave this page you will lose your changes. Are you sure you want to leave?',
  },
};

const sessionsInventory = {
  assetCount: {
    matchedTheSearchText: 'matched the search text',
    totalAsset: 'Total Asset',
    totalAssets: 'Total Assets',
  },
  hardware: {
    noItemsToDisplay: 'No Hardware items to display',
    table: {
      actions: {
        hardwareConsole: 'Hardware Console',
        resetPower: 'Reset Power',
      },
      dropdown: {
        labels: {
          reset: 'Reset Power',
        },
      },
      headings: {
        actions: 'Actions',
        description: 'Description',
        deviceType: 'Device Type',
        hardwareConsole: 'HW Console',
        interfaces: 'Interfaces',
        name: 'Name',
        powerControl: 'Power Control',
      },
    },
  },
  modal: {
    heading: 'Are you sure?',
    warning: 'You are about the perform the following action: ',
  },
  multiActionFailureAlert: {
    failedForTheFollowingNodes: 'request failed for the following nodes:',
  },
  multiSelectConfirmModal: {
    actionLabels: {
      powerOff: 'Power Off',
      powerOn: 'Power On',
      rebootGuestOs: 'Reboot Guest OS',
      reset: 'Reset',
      resetPower: 'Reset Power',
      shutdownGuestOs: 'Shutdown Guest OS',
    },
    heading: 'Are you sure?',
    subHeadingWithAction:
      'You are about to request the following action on all selected nodes: ',
    subHeadingWithoutAction:
      'You are about to request an action on all selected nodes',
  },
  progressBar: {
    actionsSubmitted: 'action requests submitted ...',
    complete: 'complete',
    error: 'error',
    errors: 'errors',
    with: 'with',
  },
  sideTabs: {
    labels: {
      hardware: 'Hardware',
      virtualMachines: 'Virtual Machines',
    },
  },
  siteTitle: 'dCloud Session Inventory',
  toasts: {
    actionRequestsFailed: ' action requests failed',
    sentSuccessfully: ' action request(s) sent successfully.',
  },
  virtualMachines: {
    getStatusesButtonLabels: {
      get: 'Get VM Statuses',
      getting: 'Getting VM Statuses ...',
      refresh: 'Refresh VM Statuses',
      refreshing: 'Refreshing VM Statuses ...',
    },
    ipAddressesTable: {
      headings: {
        adapterName: 'Adapter Name',
        ipAddress: 'IP Address',
        networkName: 'Network Name',
      },
      noDataToDisplayAlertText:
        'No IP Addresses to display for this Virtual Machine',
      viewButtonLabel: 'View All',
    },
    noItemsToDisplay: 'No Virtual Machines to display',
    table: {
      actions: {
        guestOsReboot: 'Reboot Guest OS',
        guestOsShutdown: 'Shutdown Guest OS',
        powerOff: 'Power Off',
        powerOn: 'Power On',
        reset: 'Reset',
      },
      dropdown: {
        labels: {
          powerOff: 'Power Off',
          powerOn: 'Power On',
          rebootGuestOs: 'Reboot Guest OS',
          reset: 'Reset',
          shutdownGuestOs: 'Shutdown Guest OS',
        },
      },
      headings: {
        actions: 'Actions',
        cpus: 'CPUs',
        description: 'Description',
        guestOS: 'Guest OS',
        ipAddress: 'IP Address',
        memoryGb: 'Memory (GB)',
        name: 'Name',
        nics: 'NICs',
        vmwareTools: 'VMWare Tools',
      },
    },
  },
};

const sessions = {
  fetch: {
    error: 'There was an error fetching the session, please try again.',
  },
  fetchDocument: {
    error: 'There was an error fetching the document, please try again.',
  },
  fetchVmStatus: {
    error:
      'There was an error fetching the status of the VM, please try again.',
  },
  forms: {
    extend: {
      newEndDate: {
        label: 'New End Date',
        validation: {
          required: 'Please enter a new end date',
        },
      },
      newEndTime: {
        label: 'New End Time',
        validation: {
          required: 'Please enter a new end time',
        },
      },
    },
    rename: {
      newName: {
        error: 'There was an error renaming the session, please try again.',
        label: 'New Name',
        success: 'Session Name has been updated.',
        validation: {
          maxLength: 'Enter a Name with 255 characters or less',
          required: 'Please enter a new name',
        },
      },
    },
    saveAndEnd: {
      description: {
        label: 'Description',
        validation: {
          maxDescription: 'Description cannot exceed 500 characters',
          maxName: 'Session name cannot exceed 255 characters',
          required: 'Please enter a description',
        },
      },
      error: 'There was an error saving the session. Please try again.',
      name: {
        label: 'Name',
        validation: {
          required: 'Please enter a name',
        },
      },
      retainDocumentation: {
        label: 'Retain Documentation',
      },
      success: 'Your session has been saved and will now end.',
    },
    share: {
      email: {
        label: 'Email',
        shareButton: 'Share',
        validation: {
          cisco: 'Sessions can only be shared with @cisco accounts.',
          required: 'Please enter an email address',
          unique: 'Session is already shared with this user',
          valid: 'Please enter a valid email address',
        },
      },
      error: 'There was a problem sharing your session. Please try again.',
      success: 'Session has been shared.',
    },
  },
  modals: {
    deleteNodeWarning: 'Are you sure you want to delete this node?',
    endSession: {
      error: 'There was a problem ending the session. Please try again.',
      heading: 'Stop Session?',
      subHeading: 'This will end the session. Please confirm.',
      success: 'Session has been ended successfully',
    },
    extendSession: {
      heading: 'Extend Session',
    },
    renameSession: {
      heading: 'Rename Session',
    },
    resetSession: {
      error: 'There was a problem resetting the session',
      heading: 'Reset',
      subHeading: 'Confirm session reset',
      success: 'Session has been reset',
    },
    saveAndEndSession: {
      heading: 'End and Save Session',
      subHeading: 'Saves to custom content',
    },
    sessionExpired: {
      heading: 'Session expired',
      subHeading:
        'The session has now expired. Some buttons or functionality may no longer be active or available.',
    },
  },
  panels: {
    documentation: {
      additionalResources: {
        generalHelp: 'General Help:',
        sectionHeader: 'Additional Resources',
      },
      documentation: {
        noDocumentation: 'No documents',
        sectionHeader: 'Documentation',
      },
    },
    endpointKits: {
      title: 'Endpoints',
    },
    hardware: {
      controls: {
        consoleNotAvailable:
          'Hardware console is not available for this hardware',
        hardwareConsole: 'Hardware Console',
        open: 'Open',
        power: 'Power',
        powerControlNotAvailable:
          'Power control is not available for this hardware',
        reset: 'Reset',
        sectionHeader: 'Controls',
      },
      info: {
        description: 'Description',
        deviceType: 'Device Type',
        displayName: 'Display Name',
        hwConsole: 'HW Console',
        name: 'Name',
        powerControl: 'Power Control',
        sectionHeader: 'Info',
        sessionDevice: 'Session Device',
      },
    },
    icon: {
      sections: {
        links: { messages: { empty: 'No Links found.' } },
        properties: { messages: { empty: 'No Properties found.' } },
      },
    },
    info: {
      anyConnect: {
        noAnyConnectCredentialsConfigured:
          'No AnyConnect Credentials configured',
        sectionHeader: 'AnyConnect Credentials',
      },
      dns: {
        addForm: {
          errors: {
            dnsRecordMaxLength: 'Enter a Value with 255 characters or less',
            domainRequired: 'Please select a Domain',
            onCreate: 'Error creating DNS txt record',
            textValueRequired: 'Please enter a text value.',
          },
          labels: {
            addButton: 'Add TXT Record',
            domain: 'Domain',
            name: 'Name',
            recordType: 'Record Type',
            txt: 'TXT',
          },
          messages: {
            success: 'DNS txt record created',
          },
        },
        dnsName: 'DNS Name',
        noDnsConfigured: 'No DNS configured',
        sectionHeader: 'DNS',
        type: 'Type',
        validDnsName: 'Please enter a valid Name',
      },
      endpointKits: {
        dataSSID: 'Data SSID',
        networkName: 'Network Name',
        noEndpointKitsConfigured: 'No Endpoint Kits configured',
        passphrase: 'Passphrase',
        sectionHeader: 'Endpoint Kits',
        type: 'Type',
        voiceSSID: 'Voice SSID',
      },
      phoneNumbers: {
        description: 'Description',
        externalDid: 'External (DID)',
        internalDid: 'Internal (DN)',
        noPhoneNumbersConfigured: 'No Phone Numbers configured',
        sectionHeader: 'Phone Numbers',
      },
      publicIpProxy: {
        description: 'Target',
        internalIP: 'Internal NAT IP',
        internalIpAddress: 'Internal IP Address',
        link: 'Link',
        noInternalIpConfigured: 'No Internal NAT IP configured',
        noProxyConfigured: 'No Proxy configured',
        noPublicIpConfigured: 'No Public NAT IP configured',
        privateIpAddress: 'Private IP Address',
        proxy: 'Proxy',
        publicIP: 'Public NAT IP',
        publicIpAddress: 'Public IP Address',
        sectionHeader: 'Public NAT IP / Internal NAT IP / Proxy',
        targetVm: 'Target VM',
      },
      sessionInformation: {
        endTime: 'End Time',
        heading: 'Provide the session details when you open a support request',
        lastModified: 'Last Modified',
        licenses: {
          errors: {
            noData: 'There are no session Licenses configured in this demo.',
          },
          title: 'Session Licenses',
        },
        owner: 'Owner',
        parentDemo: 'Parent Demo',
        sectionHeader: 'Session Information',
        sessionId: 'Session Id',
        vitualCenter: 'Virtual Center',
        vpnAvailable: 'VPN Available',
      },
    },
    publicCloud: {
      noItemsAlert: 'No Public Cloud integrations to display',
      title: 'Public Cloud',
    },
    saas: {
      noItemsAlert: 'No SaaS integrations to display',
      sessionNotStartedAlert: 'The integration has not completed yet.',
      title: 'SaaS',
    },
    share: {
      title: 'Share Session',
    },
    vm: {
      control: {
        guestOS: 'Guest OS',
        off: 'Off',
        on: 'On',
        power: 'Power',
        reboot: 'Reboot',
        reset: 'Reset',
        sectionHeader: 'Control',
        shutdown: 'Shutdown',
      },
      info: {
        description: 'Description',
        guestOs: 'Guest OS',
        memory: 'Memory',
        name: 'Name',
        osFamily: 'OS Family',
        sectionHeader: 'Info',
        vCPUs: 'vCPUs',
        vmWareTools: 'VMWare tools',
      },
      ipAddress: {
        sectionHeader: 'IP Address',
      },
      remoteAccess: {
        internalUrl1: 'Internal URL 1',
        internalUrl2: 'Internal URL 2',
        noRemoteAccessConfigured: 'No Remote Access configured',
        password: 'Password',
        sectionHeader: 'Remote Access',
        ssh: 'SSH',
        sshIpAddress: 'SSH IP Address',
        user: 'User',
        vmConsole: 'VM Console',
        webRDP: 'Web RDP',
        webRdpIpAddress: 'Web RDP IP Address',
      },
    },
  },
  postHwAction: {
    error: 'There was an error performing this action, please try again.',
    success: 'Reset request sent.',
  },
  postVmAction: {
    error: 'There was an error performing this action, please try again.',
    success: ':action request sent.',
  },
  rdp: 'RDP',
  siteTitle: 'dCloud Session View',
  ssh: 'SSH',
  vmConsole: 'CON',
};

const startStopOrder = {
  hardwareStart: {
    fetch: {
      errors: {
        many: 'There was an error fetching the Hardware Start order, please try again.',
      },
    },
    name: 'Hardware Start',
    table: {
      headings: {
        name: 'Name',
        position: 'Position',
      },
      noResults: 'No Hardware',
    },
    update: {
      error:
        'There was an error updating Hardware start order, please try again.',
      success: 'Hardware start order updated successfully',
    },
  },
  name: 'Start & Stop Order',
  vmStart: {
    fetch: {
      errors: {
        many: 'There was an error fetching VM Start Order, please try again.',
      },
    },
    name: 'VM Start',
    table: {
      headings: {
        delay: 'Delay (seconds)',
        name: 'Name',
      },
      noResults: 'No Virtual Machines',
    },
    update: {
      error: 'There was an error updating VM start order, please try again.',
      success: 'VM start order updated successfully',
    },
  },
  vmStop: {
    fetch: {
      errors: {
        many: 'There was an error fetching VM Stop Order, please try again.',
      },
    },
    name: 'VM Stop',
    table: {
      headings: {
        name: 'Name',
        position: 'Position',
      },
      noResults: 'No Virtual Machines',
    },
    update: {
      error: 'There was an error updating VM stop order, please try again.',
      success: 'VM stop order updated successfully',
    },
  },
};

const remoteAccess = {
  fetch: {
    error: 'There was an error fetching Remote Access, please try again.',
  },
  formLabels: {
    anyconnectEnabled: 'Enable AnyConnect',
    endpointKitEnabled: 'Enable Endpoint Kit',
  },
  name: 'Remote Access',
  update: {
    error: 'There was an error updating Remote Access, please try again.',
    success: 'Remote Access updated successfully',
  },
};

const templateManager = {
  demoTemplates: {
    create: {
      error:
        'There was a problem creating the Demo Template, Please try again.',
      success: 'Demo Template created.',
    },
    delete: {
      error:
        'There was a problem deleting the Demo Template, Please try again.',
      success: 'Demo Template has been deleted.',
    },
    fetch: {
      errors: {
        many: 'There was a problem fetching the Demo Templates, Please try again.',
        one: 'There was a problem fetching the Demo Template, Please try again.',
      },
    },
    form: {
      labels: {
        architecture: 'Architecture',
        available: 'Available Architectures',
        contact: 'Contact',
        demoId: 'Demo ID',
        description: 'Description',
        name: 'Name',
        selectedArchitectures: 'Selected Architectures',
        userEnabled: 'User Enabled',
      },
      titles: {
        create: 'Create Demo Template',
        edit: 'Edit Demo Template',
      },
      validations: {
        contactFormat: 'Please enter a valid cisco email',
        contactLength: 'Enter a Contact with 255 characters or less',
        description: 'Description is required',
        descriptionMaxLength: 'Enter a Description with 512 characters or less',
        missingArchitecture: 'Please remove missing Architecture(s)',
        nameLength: 'Enter a Name with 255 characters or less',
        nameRequired: 'Template name is required',
      },
    },
    info: {
      datacenter: 'Datacenter',
      demoId: 'Demo ID',
      lastUpdated: 'Last Updated',
      lastUpdatedBy: 'Last Updated By',
      originalName: 'Original Name',
      topologyId: 'Topology ID',
    },
    tabTitle: 'Demos',
    table: {
      headings: {
        active: 'User Enabled',
        architecture: 'Architecture',
        contact: 'Contact',
        demoId: 'Demo ID',
        description: 'Description',
        licensed: 'Licensed',
        name: 'Name',
      },
    },
    title: 'Demo Templates',
    update: {
      error:
        'There was a problem updating the Demo Template, Please try again.',
      success: 'Demo Template has been updated.',
    },
  },
  metadata: {
    fetch: {
      error: 'There was a problem contacting the metadata service.',
    },
  },
  title: 'Manage Templates',
  vmTemplates: {
    create: {
      button: 'Create Template',
      error: 'There was a problem creating the VM Template, Please try again.',
      success: 'VM Template created.',
      title: 'Create Virtual Machine Template',
    },
    delete: {
      error: 'There was a problem deleting the VM Template, Please try again.',
      success: 'Template has been deleted.',
    },
    fetch: {
      errors: {
        many: 'There was a problem fetching the Templates, Please try again.',
        one: 'There was a problem fetching the VM Template, Please try again.',
      },
    },
    form: {
      labels: {
        alert:
          'Please Update the vm template to enable editing of Vm information',
        architecture: 'Architecture',
        available: 'Available Architectures',
        contact: 'Contact',
        description: 'Description',
        lastUpdated: 'Last Updated',
        licensed: 'Licensed',
        managementIp: 'Management IP',
        name: 'Name',
        password: 'Password',
        selectedArchitectures: 'Selected Architectures',
        userEnabled: 'User Enabled',
        userName: 'Username',
        version: 'Version',
        virtualHardware: 'Virtual HW',
        vmId: 'VM ID',
      },
      titles: {
        create: 'Create VM Template',
        edit: 'Virtual Machine Template',
        templateData: 'Template Data',
      },
      validations: {
        contactFormat: 'Please enter a valid cisco email',
        contactLength: 'Enter a Contact with 255 characters or less',
        description: 'Description is required',
        descriptionMaxLength: 'Enter a Description with 512 characters or less',
        ipAddress: 'Please enter a valid IPv4 Address',
        missingArchitecture: 'Please remove missing Architecture(s)',
        missingVm: 'Vm not found',
        nameLength: 'Enter a Name with 255 characters or less',
        nameRequired: 'Template name is required',
        passwordLength: 'Enter a Password with 255 characters or less',
        templateVersionLength:
          'Enter a Template Version with 255 characters or less',
        userNameLength: 'Enter a UserName with 255 characters or less',
      },
    },
    tabTitle: 'Virtual Machines',
    table: {
      headings: {
        active: 'User Enabled',
        architecture: 'Architecture',
        contact: 'Contact',
        description: 'Description',
        lastUpdated: 'Last Updated',
        licensed: 'Licensed',
        name: 'Name',
        version: 'Version',
        virtualHardware: 'Virtual HW',
        vmId: 'VM ID',
      },
    },
    title: 'VM Templates',
    update: {
      error: 'There was a problem updating the VM Template, Please try again.',
      success: 'VM Template has been updated.',
    },
  },
};

export default {
  accessRoleAssignment: {
    add: {
      error: 'There was an error Sharing this Topology, please try again.',
      success: 'Topology shared successfully',
    },
    delete: {
      error:
        'There was an error removing access to the Topology, please contact support.',
      heading: 'Remove Access to Topology',
      subHeading: 'Are you sure you want to remove access to this Topology?',
      success: 'Removed Access to Topology successfully',
    },
    empty: 'Not Shared with anyone',
    fetch: {
      error:
        'There was an error fetching Sharing information, please try again.',
    },
    forms: {
      email: {
        validation: {
          required: 'Please enter an email address',
          valid: 'Please enter a valid email address',
        },
      },
    },
    name: 'Sharing',
    table: {
      headings: {
        email: 'Email',
      },
    },
  },
  buttons,
  common,
  configuration,
  createTopology,
  currentUser,
  dataCenters,
  demos,
  diagram,
  dnsAssets: {
    fetch: {
      error: 'There was an error fetching DNS Assets, please try again.',
    },
  },
  documentation: {
    documentationUrl: 'Documentation URL',
    error: {
      documentationUrl: 'Documentation URL must be a valid URL',
    },
    fetch: {
      error: 'There was an error fetching documentation, please try again.',
    },
    name: 'Documentation',
    update: {
      error: 'There was an error updating documentation, please try again.',
      success: 'Documentation updated successfully.',
    },
  },
  errors: {
    general: 'An error occurred, please try again later.',
  },
  evcModes: {
    fetch: {
      error: 'There was an error fetching EVC Modes, please try again.',
    },
  },
  footer,
  general: {
    fetch: {
      error:
        'There was an error fetching General information, please try again.',
    },
    form: {
      description: {
        label: 'Description',
        validation: {
          required: 'Please enter a Description',
        },
      },
      name: {
        label: 'Name',
        validation: {
          maxLength: 'Enter a Name with 255 characters or less',
          required: 'Please enter a Name',
        },
      },
      notes: {
        label: 'Notes',
      },
      save: 'Save',
    },
    name: 'General',
    update: {
      error:
        'There was an error updating General information, please try again.',
      success: 'General fields updated successfully.',
    },
  },
  hardware,
  helpButtonLinks,
  home,
  icons,
  integrations: {
    delete: {
      ariaLabel: 'Delete Integration',
      error:
        'There was an error deleting the integration. Please contact support for a resolution.',
      heading: 'Delete :name',
      subHeading: 'Are you sure you want to delete this Integration?',
      success: 'Integration deleted successfully.',
    },
    fetch: {
      error: 'There was an error fetching integrations, please try again.',
      recipeError: 'There was an error fetching IDAC recipe, please try again.',
      recipesError:
        'There was an error fetching IDAC recipes, please try again.',
    },
    filterAccordian: {
      accordianTitle: 'Filters',
      accordianTypes: 'Type',
    },
    filterWarning: 'No Integrations to display.',
    forms: {
      add: {
        error:
          'There was a communication error while saving this Integration. Please try again or contact support.',
        success: 'Integration created successfully!',
        title: 'Add Integration',
      },
      common: {
        availabilityStatus: {
          label: 'Status',
          validations: {
            required: 'Please select an availability status',
          },
        },
        availabilityStatusMessage: {
          label: 'Status message',
          placeholder: 'Type message here',
          validations: {
            maxLength:
              'Please enter a status message with 255 characters or less',
            required: 'Please enter a status message',
          },
        },
        description: {
          label: 'Description',
          validations: {
            maxLength: 'Please enter a description with 255 characters or less',
            required: 'Please enter a description',
          },
        },
        details: {
          label: 'Details',
          validations: {
            maxLength: 'Please enter details with 4000 characters or less',
            required: 'Please enter details',
          },
        },
        icon: {
          errors: {
            integrationTypeNotSelected: {
              buttonLabel: 'Select Icon',
              modalMessage:
                'Please select an integration type to view the icons available to you.',
              modalTitle: 'Select an icon',
            },
          },
          label: 'Icon',
          values: {
            aws: 'AWS',
            cloud: 'Public Cloud',
            duo: 'Duo',
            saaSConnect: 'SaaS',
            secureEndPoint: 'Secure End Point',
            thousandEyes: 'Thousand Eyes',
            umbrella: 'Umbrella',
          },
        },
        inputs: {
          default: 'Default Value',
          description: 'Description',
          errors: {
            defaultMaxLength:
              'Please enter a default value with 255 characters or less.',
            defaultRequired: 'Please enter a default value.',
            descriptionMaxLength:
              'Please enter a description with 255 characters or less.',
            descriptionRequired: 'Please enter a description.',
            nameMaxLength:
              'Please enter a user input field name with 255 characters or less.',
            nameRequired: 'Please enter user input field name.',
            noDataFound: 'None available',
          },
          name: 'User Input Field',
          optional: 'Optional',
          required: 'Required',
          userConfigurable: 'User Configurable',
        },
        integrationType: {
          errors: {
            required: 'Please select an Integration type',
          },
          name: 'Type',
        },
        licenses: {
          available: 'Available Licenses',
          errors: {
            fetch: 'There was an error fetching licenses, please try again.',
          },
          selected: 'Selected Licenses',
        },
        maxAmountPerTopology: {
          label: 'Max amount per topology',
          validations: {
            required: 'Please enter a number between 1 and 999.',
          },
        },
        name: {
          label: 'Name',
          validations: {
            maxLength: 'Please enter name with 255 characters or less',
            required: 'Please enter name',
            uniqueNameError: 'Please provide a unique name. This one is taken.',
          },
        },
        recipe: {
          label: 'IDAC Recipe',
          validations: {
            required: 'Please enter IDAC recipe',
          },
        },
        saasTemplateAvailabilityValues: {
          errors: {
            fetch:
              'There was an error fetching template availability options, please try again.',
          },
        },
        sessionView: {
          label: 'Session View',
          values: {
            allow: 'Allow',
            force: 'Force',
            hide: 'Hide',
          },
        },
        workflowParameterMappings: 'Workflow Parameter Mappings',
      },
      edit: {
        ariaLabel: 'Modify Integration',
        error:
          'There was a communication error while updating this Integration. Please try again or contact support.',
        success: 'Integration updated successfully!',
        title: 'Edit Integration',
        typeChangeWarning: {
          heading: 'Warning: You have changed the integration type',
          subheading:
            'The icon has updated to reflect this change, please save to confirm your changes.',
        },
      },
    },
    info: {
      uses: {
        fetch: {
          error: 'There was an error fetching topologies, please try again.',
        },
        noTopologies: 'There are no uses of the integration.',
        table: {
          headings: {
            datacenter: 'Datacenter',
            demoId: 'Demo ID',
            inCurrentVersion: 'In current version',
            inDemo: 'In demo',
            inPreviousVersions: 'In previous versions',
            name: 'Name',
            owner: 'Owner',
            uid: 'Uid',
          },
          values: {
            notApplicable: 'N/A',
          },
        },
        title: 'Integration Usage',
      },
    },
    menu: 'SaaS',
    modals: {
      add: {
        title: 'Add Integration',
      },
    },
    noData: 'No Integration found.',
    searchWarning: 'Please enter at least 3 characters',
    table: {
      availability: 'Availability',
      description: 'Description',
      integration: 'Name',
      integrationTypes: {
        publicCloud: 'Public Cloud',
        saas: 'SaaS',
      },
      owner: 'Created By',
      tags: 'Tags',
      type: 'Type',
    },
    title: 'Manage Integrations',
    type: {
      publicCloud: 'Public Cloud',
      saas: 'SaaS',
    },
  },
  inventoryHardware: {
    add: {
      title: 'Add Hardware',
    },
    fetch: {
      error:
        'There was an error fetching the inventory hardware, please try again.',
    },
    table: {
      headings: {
        description: 'Description',
        hardwareConsoleAvailable: 'HW Console Available',
        interfaces: 'Interfaces',
        name: 'Name',
        powerControlAvailable: 'Power Control Available',
      },
    },
  },
  inventoryLicences: {
    fetch: {
      error:
        'There was an error fetching the inventory licenses, please try again.',
    },
  },
  inventoryNetworks: {
    fetch: {
      error:
        'There was an error fetching the inventory networks, please try again.',
    },
  },
  inventoryTopologies,
  inventoryVirtualMachines,
  licences: {
    add: {
      error: 'There was an error adding the license, please try again.',
      success: 'License added successfully.',
      title: 'Add Licenses',
    },
    delete: {
      error: 'There was an error deleting the license, please try again.',
      heading: 'Delete :licence',
      subHeading: 'Are you sure you want to delete this item?',
      success: 'License deleted successfully.',
    },
    edit: {
      error: 'There was an error updating the license, please try again.',
      success: 'License updated successfully.',
      title: 'Edit License',
    },
    fetch: {
      error: 'There was an error fetching licenses, please try again.',
    },
    name: 'Licenses',
    noLicences: {
      alertText: 'No Licenses',
    },
    table: {
      headings: {
        name: 'Name',
        quantity: 'Quantity',
      },
    },
  },
  modals,
  networks: {
    delete: {
      error: 'There was an error deleting the network, please try again.',
      heading: 'Delete :network',
      subHeading: 'Are you sure you want to delete this item?',
      success: 'Network deleted successfully.',
    },
    fetch: {
      errors: {
        many: 'There was an error fetching networks, please try again.',
        one: 'There was an error fetching the network, please try again.',
      },
    },
    form: {
      add: {
        error: 'There was an error adding your network, please try again.',
        success: 'Network added successfully.',
        title: 'Add Network',
      },
      description: {
        label: 'Description',
        validation: {
          maxLength: 'Enter a Description with 500 characters or less',
          required: 'Please enter a Description',
        },
      },
      edit: {
        hiddenFromSession: 'Hide Network',
        title: 'Edit Network',
      },
      maxNumNetworksReached:
        'You have reached the maximum number of Networks for a Topology.',
      maxRoutedInfo:
        'There are already 5 routed networks which is the maximum per topology',
      maxUnroutedInfo:
        'There are already 20 non routed networks which is the maximum per topology',
      minRoutedInfo: 'Must have at least one routed network',
      name: {
        label: 'Name',
        validation: {
          maxLength: 'Enter a Name with 255 characters or less',
          noSpecialCharacters: 'Please enter a Name with no special characters',
          required: 'Please enter a Name',
          unique: 'Please enter a unique Name',
        },
      },
      routedByVpodGw: 'Routed by vPodGW',
      subnet: {
        validation: {
          required: 'Please choose a Subnet',
        },
      },
    },
    name: 'Networks',
    table: {
      defaultNetworkTooltip:
        'This routed network is mandatory and cannot be removed. You can change the name and description only.',
      headings: {
        description: 'Description',
        name: 'Name',
        routed: 'Routed by vPodGW',
        subnet: 'Subnet',
      },
    },
    update: {
      error: 'There was an error updating your network, please try again.',
      success: 'Network updated successfully.',
    },
  },
  operatingSystems: {
    fetch: {
      error: 'There was an error fetching Operating Systems, please try again.',
    },
  },
  remoteAccess,
  saas: {
    add: {
      fetch: {
        error: 'There was an error fetching integrations, please try again.',
      },
      formErrors: {
        nameUnique: 'Please provide a unique name. This one is taken.',
        params: {
          maxLength: 'Please enter a value with 255 characters or less.',
          required: 'Please enter a value',
        },
      },
      headings: {
        publicCloud: 'Add Public Cloud Integration',
        saas: 'Add SaaS Integration',
      },
      noItemsAlert: 'No SaaS integrations to display',
      noItemsAlertCloud: 'No Public Cloud integrations to display',
      noItemsSearchResult: 'No integrations matched your search selection.',
      noWorkFlowParams: 'No Workflow Parameter Mappings to display',
      table: {
        headings: {
          configured: 'Configured',
          description: 'Description',
          maxPerTopology: 'Maximum per Topology',
          name: 'Name',
          tags: 'Tags',
        },
      },
      title: 'Add',
      workFlowParametersSubHeading: 'Workflow Parameter Mappings',
    },
    common: {
      fields: {
        defaultToWorkflowEngine: {
          subTitle: 'Default value is subject to change',
          title: 'Use Default',
        },
      },
    },
    configure: {
      formErrors: {
        allowedChars:
          'Please enter a name containing alphanumeric characters, underscores, dots, spaces or hyphens',
        errorCreatingForm:
          'An error occured building the Configure form. Please try again.',
        nameLimit: 'Please enter a name with 255 characters or less.',
        nameUnique: 'Please provide a unique name. This one is taken.',
        required: 'Please enter a name.',
      },
      formLabels: {
        description: 'Description',
        hideOnSessionView: 'Hide on session view',
        integrationName: 'Integration Name',
        name: 'Name',
      },
      headings: {
        publicCloud: 'Configure Public Cloud Integration',
        saas: 'Configure SaaS Integration',
      },
    },
    create: {
      error: 'There was an error adding the integration, please try again.',
      success: 'Integration addded successfully.',
    },
    delete: {
      confirm: {
        heading: 'Delete :saasIntegration',
        subheading:
          'Are you sure you want to delete this integration? Please confirm.',
      },
      error:
        'There was an error deleting the Integration, please try again or contact support for a resolution',
      success: 'Integration deleted successfully.',
    },
    edit: {
      headings: {
        publicCloud: 'Edit Public Cloud Integration',
        saas: 'Edit SaaS Integration',
      },
    },
    fetch: {
      error: 'There was an error fetching the integration, please try again.',
    },
    nameCloud: 'Public Cloud Integrations',
    nameIntegrations: 'Integrations',
    nameSaas: 'SaaS Integrations',
    search: {
      noItemsFound: {
        cloud: 'No Public Cloud integrations found',
        saas: 'No SaaS integrations found',
      },
    },
    sideBar: {
      publicCloud: 'Public Cloud',
      root: 'Integrations',
      saas: 'SaaS',
    },
    update: {
      configure: 'Configure',
      error: 'There was an error updating the integration, please try again.',
      success: 'Integration updated successfully',
    },
  },
  scenarios: {
    add: {
      headerText: 'Add a scenario option',
    },
    delete: {
      ariaLabel: 'Delete scenario option',
      confirm: {
        heading: 'Delete this scenario option?',
        subHeading:
          'Are you sure you want to delete this scenario option? Please confirm.',
      },
    },
    displayName: 'Display Name',
    edit: {
      ariaLabel: 'Modify scenario option',
    },
    enabled: 'Enabled',
    fetch: {
      error: 'There was an error fetching scenarios, please try again.',
    },
    footerText:
      'Only alphanumeric, underscores(_) and hyphens(-) are allowed in Scenario Name.',
    formErrors: {
      duplicate: 'Please enter a unique scenario option.',
      minLimit: {
        heading: 'Delete this scenario option?',
        subHeading:
          'You need to have at least 1 scenario option in order to proceed.',
      },
      optionAllowedChars:
        'Please enter a value with no spaces or special characters except hyphens and underscores',
      optionLimit: 'Please enter an Option with 50 characters or less.',
      questionLimit: 'Enter a scenario question with 150 characters or less.',
      questionRequired: 'Please enter a scenario question.',
    },
    name: 'Scenarios',
    question: 'Question',
    save: {
      buttonText: 'Save',
      error: 'There was an error updating Scenarios, please try again.',
      success: 'Scenarios saved successfully.',
    },
    scenarioName: 'Scenario Name',
    update: {
      ariaLabel: 'Update scenario option',
    },
  },
  sessions,
  sessionsInventory,
  siteTitle: 'dCloud Topology Builder',
  startStopOrder,
  tags: {
    errors: {
      fetch: 'There was an error fetching tags, please try again.',
      maxLength: 'Please enter a tag name with 32 characters or less',
      search:
        'No existing tags found. Please press enter to create a custom tag.',
    },
    label: 'Tags',
    startTyping: 'Start typing..',
    suggestions: 'Suggestions',
  },
  telephony,
  templateManager,
  topologies,
  traffic: {
    dnsAssets: {
      error:
        'There was an error fetching Inventory DNS Assets, please try again.',
    },
    externalDns: {
      add: {
        error:
          'There was an error creating the External DNS record, please try again.',
        success: 'External DNS record created successfully',
      },
      addButton: 'Add A Record',
      delete: {
        error:
          'There was an error deleting the External DNS record, please try again.',
        heading: 'Delete External DNS',
        subHeading: 'Are you sure you want to delete this External DNS record?',
        success: 'External DNS record deleted successfully',
      },
      edit: {
        formErrors: {
          portFormat: 'Port must be a number between 0 and 65535',
          portRange: 'Port must be within 0 and 65535',
          protocolRequired: 'Please select a Protocol.',
          serviceMatch:
            'Service must begin with "_" followed by a lowercase alphanumeric.',
          serviceMaxLength: 'Enter a Service with 19 characters or less.',
          serviceRequired: 'Service is Required.',
        },
        general: {
          aRecord: 'A',
          heading: 'General',
        },
        srv: {
          addButton: 'Add SRV Record',
          empty:
            'You do not have any SRV records. Add one using the box above.',
          heading: 'SRV Records',
          table: {
            headings: {
              port: 'Port',
              protocol: 'Protocol',
              service: 'Service',
            },
          },
        },
      },
      empty: 'No External Records',
      fetch: {
        error:
          'There was an error fetching External Dns records, please try again.',
      },
      formErrors: {
        hostnameMatch:
          'Please enter a Hostname consisting of alphanumeric hyphen characters',
        hostnameMaxLength: 'Enter a Hostname with 63 characters or less',
        hostnameRequired: 'Hostname is required.',
        natRuleNotSelected: 'Please select a NAT rule.',
      },
      name: 'External DNS',
      noConfiguredNatRules:
        'You must configure a Public NAT Rule before using DNS.',
      table: {
        headings: {
          aRecord: 'A',
          asset: 'Asset',
          hostname: 'Hostname',
          nat: 'NAT',
        },
      },
      update: {
        error:
          'There was an error updating the External Dns Record, please try again.',
        success: 'External Dns Record updated successfully.',
      },
    },
    inboundProxyRules: {
      add: {
        error:
          'There was an error creating the Inbound Proxy rule, please try again.',
        success: 'Inbound Proxy Rule created successfully',
      },
      delete: {
        error:
          'There was an error deleting the Inbound Proxy Rule, please try again.',
        heading: 'Delete Inbound Proxy Rule',
        subHeading: 'Are you sure you want to delete this Inbound Proxy Rule?',
        success: 'Inbound Proxy Rule deleted successfully',
      },
      empty: 'No Inbound Proxy Rules',
      error:
        'There was an error fetching Inbound Proxy Rules, please try again.',
      formErrors: {
        hyperLinkTextMax: 'Please enter a value with 15 characters or less',
        hyperLinkTextRequired: 'Please enter Hyperlink Text',
        inUse: 'VM already has an Inbound Proxy Rule defined',
        ipAddressRequired: 'Please select an IP Address',
        maxRulesPart1: 'You cannot add more than',
        maxRulesPart2: 'Inbound proxy rules to a topology',
        targetRequired: 'Please select a Target',
        tcpPortMax: 'Please enter a value less than or equal to 65535',
        tcpPortMin: 'Please enter a value greater than or equal to 0',
        tcpPortRequired: 'Please enter a TCP Port',
        urlPathMatch: 'Please enter a valid URL Path',
        urlPathMax: 'Please enter a value with 255 characters or less',
        urlPathRequired: 'Please enter a URL Path',
      },
      name: 'Inbound Proxy Rules',
      noConfiguredVMs:
        'You must configure a VM with an IP Address before using Inbound Proxy Rules',
      table: {
        hyperlinkText: 'Hyperlink Text',
        ipAddress: 'IP Address',
        showHyperlink: 'Show Hyperlink',
        ssl: 'SSL',
        target: 'Target',
        tcpPort: 'TCP Port',
        urlPath: 'URL Path',
      },
    },
    mailServer: {
      add: {
        error: 'There was an error creating the Mail Server, please try again.',
        success: 'Mail Server created successfully',
      },
      delete: {
        error: 'There was an error deleting the Mail Server, please try again.',
        heading: 'Delete Mail Server',
        subHeading: 'Are you sure you want to delete this Mail Server?',
        success: 'Mail Server deleted successfully',
      },
      empty: 'No Mail Servers',
      fetch: {
        error: 'There was an error fetching Mail Servers, please try again.',
      },
      formErrors: {
        assetNotSelected: 'Please select an Asset',
        ipAddressNotSelected: 'Please select an IP address',
        vmNotSelected: 'Please select a Virtual Machine',
      },
      name: 'Mail Server',
      noConfiguredMailserver:
        'You must configure a VM with an IP Address before using Mail Server.',
      table: {
        headings: {
          asset: 'Asset',
          ipAddress: 'IP Address',
          virtualMachine: 'Virtual Machine',
        },
      },
    },
    name: 'Traffic',
    natRules: {
      add: {
        error: 'There was an error creating the NAT rule, please try again.',
        success: 'NAT Rule created successfully',
        type: {
          internalNatToIp: 'Internal NAT to IP Address',
          internalNatToVm: 'Internal NAT to VM',
          publicNatToIp: 'Public NAT to IP Address',
          publicNatToVm: 'Public NAT to VM',
        },
      },
      delete: {
        error: 'There was an error deleting the NAT Rule, please try again.',
        heading: 'Delete NAT Rule',
        inUseWarning:
          'This NAT rule is associated with one or more external DNS records. ',
        subHeading: 'Are you sure you want to delete this NAT Rule?',
        success: 'NAT Rule deleted successfully',
      },
      empty: 'No NAT Rules',
      fetch: {
        error: 'There was an error fetching NAT Rules, please try again.',
      },
      formErrors: {
        ipAddressInvalid: 'Please enter a valid IPv4 Address',
        ipAddressNotSelected: 'Please select an IP address',
        targetMax: 'Enter a Name with 50 characters or less',
        targetNotSelected: 'Please select a VM',
        targetRequired: 'Name cannot be blank',
        typeRequired: 'Please select a type',
        unique: 'This IP Address is already in use.',
      },
      infoBanner:
        'Internal IP addresses are used within a local network to identify devices and facilitate communication among them, whereas public IP addresses are assigned to devices to allow them to be identified and accessed over the internet.',
      name: 'NAT Rules',
      noConfiguredVms:
        'You must configure a VM with an IP Address before using NAT Rules.',
      table: {
        headings: {
          eastWestNat: 'East/West NAT',
          ipAddress: 'IP Address',
          target: 'Target',
          type: 'Type',
        },
      },
    },
    noAvailableNicTargets:
      'All available IP addresses for selected target are in use. Create a new Network interface for selected VM.',
    vmNicTargets: {
      fetch: {
        error: 'There was an error fetching VM targets, please try again.',
      },
    },
  },
  userPreferences: {
    autoAlign: 'Auto Align:',
    clearAll: 'Clear All',
    deleteAll: 'Delete Preferences',
    filters: 'Topologies Filter selection:',
    note: 'Note: Browser tab needs to be closed or page has to be refreshed after deleting Preferences.',
    reset: 'Reset',
    selectAll: 'Select All',
    theme: 'Theme selection:',
    title: 'View / Delete Preferences',
    viewType: 'Topologies View Type selection:',
  },
  videos,
  virtualMachines,
};
