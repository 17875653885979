import { EVC_MODES_PATH } from '../../api/paths';
import i18n from '../../i18n';
import { dcloudApi } from '../api';
import { TAGS } from '../api/constants';

export const evcModesApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getEvcModes: build.query<GetEvcModesResponse, undefined>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.EVC_MODES],
      query: () => ({
        errorMessage: i18n.t('evcModes.fetch.error'),
        method: 'get',
        url: EVC_MODES_PATH,
      }),
    }),
  }),
});

export const { useGetEvcModesQuery } = evcModesApi;
