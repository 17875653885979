import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  clearTopologyToClone,
  topologiesSelectors,
} from '../../../../redux/topologies/slice';
import { RootState } from '../../../../redux/store';
import { useCloneTopologyMutation } from '../../../../redux/topologies/api';
import { CloneTopologyModal } from '.';

export function CloneTopologyFormContainer({
  topologyToClone,
  clearTopologyToClone,
  isConfigurationPage,
}: CloneTopologyFormContainerProps): ReactElement {
  const [cloneTopology, { isLoading: isCloning }] = useCloneTopologyMutation();

  return (
    <CloneTopologyModal
      isCloning={isCloning}
      cloneTopology={cloneTopology}
      topologyToClone={topologyToClone}
      clearTopologyToClone={clearTopologyToClone}
      isConfigurationPage={isConfigurationPage}
    />
  );
}
export type CloneTopologyFormContainerProps = ReturnType<
  typeof mapStateToProps
> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  isConfigurationPage:
    topologiesSelectors.getIsTopologyToCloneCalledFromConfigurationPage(state),
  topologyToClone: topologiesSelectors.getTopologyToClone(state),
});

const mapDispatchToProps = {
  clearTopologyToClone,
};

export type CloneTopologyModalProps = CloneTopologyFormContainerProps & {
  isCloning: boolean;
  cloneTopology: RTKMutation<CloneTopologyPostPayload, Topology>;
};

export const ConnectedCloneTopologyModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloneTopologyFormContainer);
