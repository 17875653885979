import React, { ReactElement, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Node } from '../Node';
import { usePosition } from '../Node/hooks/use-position';
import { useConnection } from '../Connections/hooks/use-connection';
import styles from './IconNode.module.css';
import { IconNodeDefaultPosition as defaultPosition } from './constants';
import { useIconDrag } from './hooks/use-drag';
import {
  determineStaticIconForDiagram,
  iconNodeHandleOnClick,
} from './utils/utils';
import { IconNodeProps } from './container';
import { NodeIconDeleteButtonContainer } from './components/DeleteButton/container';
import { NodeIconEditButtonContainer } from './components/EditButton/container';

export function IconNode({
  display,
  isReadOnly,
  uid,
  expired,
  setLeftPanelDeviceUid,
  setLeftPanelType,
  isSnapToGridEnabled,
  isAddingLogicalConnection,
  isDrawingLogicalConnectionFrom,
  setIsDrawingLogicalConnectionFrom,
}: IconNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();
  const isValidIconDiagramNode = !!uid && !isReadOnly;
  const iconClassName = isValidIconDiagramNode ? styles.glow : undefined;

  const hookProps = {
    defaultPosition,
    display,
    isAddingLogicalConnection,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useIconDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });

  const renderActionButtons = useCallback(
    (isReadOnly: boolean) => {
      if (isReadOnly || isAddingLogicalConnection) return <></>;

      return (
        <div className={styles.buttons}>
          <NodeIconDeleteButtonContainer
            iconToDelete={{ name: display.name, uid }}
          />
          <NodeIconEditButtonContainer iconToEdit={{ display, uid }} />
        </div>
      );
    },
    [display, isAddingLogicalConnection, uid],
  );

  const handleOnClick = () => {
    iconNodeHandleOnClick({
      expired,
      isReadOnly,
      setLeftPanelDeviceUid,
      setLeftPanelType,
      uid,
    });
  };

  return (
    <Node
      className={classNames(styles.root, {
        [styles.addingLogicalConnectionGlow]: isAddingLogicalConnection,
      })}
      ref={nodeRef}
      isReadOnly={isReadOnly}
      uid={uid}
      onClick={handleOnClick}
      isAddingLogicalConnection={isAddingLogicalConnection}
      isDrawingLogicalConnectionFrom={isDrawingLogicalConnectionFrom}
      setIsDrawingLogicalConnectionFrom={setIsDrawingLogicalConnectionFrom}
      isConnectionAllowed={true}
      isIconNode={true}
    >
      <>
        <p className={styles.title}>{display?.name}</p>
        {determineStaticIconForDiagram({
          className: iconClassName,
          display,
          uid,
        })}
        {renderActionButtons(isReadOnly)}
      </>
    </Node>
  );
}
