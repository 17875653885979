import { dcloudApi } from '../../../api';
import { TOPOLOGIES_PATH, VM_STOP_ORDER } from '../../../../api/paths';
import { setOriginal, setStartStopOrder } from '../slice';
import i18n from '../../../../i18n';
import { TAGS } from '../../../api/constants';

export const vmStopOrderApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getVmStopOrder: build.query<StartStopOrder, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setStartStopOrder(data));
          dispatch(setOriginal(data));
        } catch (err) {
          throw err;
        }
      },
      providesTags: [TAGS.VM_STOP_ORDER],
      query: (topologyUid) => ({
        errorMessage: i18n.t('startStopOrder.vmStop.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${VM_STOP_ORDER}`,
      }),
    }),
    getVmStopOrderItem: build.query<string, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.VM_STOP_ORDER_ITEM],
      query: (vmStopOrderUid) => ({
        errorMessage: i18n.t('startStopOrder.vmStop.fetch.error'),
        method: 'get',
        url: `${VM_STOP_ORDER}/${vmStopOrderUid}`,
      }),
    }),
    updateVmStopOrder: build.mutation<VirtualMachine, StartStopOrderPayload>({
      invalidatesTags: [
        TAGS.VM_STOP_ORDER,
        TAGS.VM_STOP_ORDER_ITEM,
        TAGS.TOPOLOGY,
      ],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('startStopOrder.vmStop.update.error'),
        method: 'PUT',
        successMessage: i18n.t('startStopOrder.vmStop.update.success'),
        url: `${VM_STOP_ORDER}/${payload.uid}`,
      }),
    }),
  }),
});

export const {
  useGetVmStopOrderQuery,
  useGetVmStopOrderItemQuery,
  useUpdateVmStopOrderMutation,
} = vmStopOrderApi;
