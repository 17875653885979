import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import {
  determineIsEditingAsset,
  determinePushAction,
} from '../../../../../../../entities/topologies/utils';
import { useIsPushingAndRefreshingData } from '../../../Push/hooks/useIsPushingAndRefreshingData';
import { EnabledScheduleButton } from './components/EnabledScheduleButton';
import { DisabledScheduleButton } from './components/DisabledScheduleButton';
import { ScheduleButtonProps } from './container';

export const ScheduleButton = ({
  isPushComplete,
  isFetchingTopology,
  isPushing,
  setIsScheduleModalVisible,
  setIsPushModalVisible,
  topology,
}: ScheduleButtonProps): ReactElement => {
  const isPushingAndRefreshingData = useIsPushingAndRefreshingData({
    isPushComplete,
    isPushing,
  });

  const { pathname } = useLocation();

  if (!topology || isPushingAndRefreshingData || isFetchingTopology) {
    return <></>;
  }

  const action = determinePushAction(topology, undefined, undefined, true);
  const isEditingAsset = determineIsEditingAsset({ pathname });

  if (
    action !== 'PUSH_UPDATES_DISABLED' &&
    action !== 'PUSH_DISABLED' &&
    !isEditingAsset &&
    !isPushingAndRefreshingData &&
    !isFetchingTopology
  ) {
    return (
      <EnabledScheduleButton
        setIsScheduleModalVisible={setIsScheduleModalVisible}
        setIsPushModalVisible={setIsPushModalVisible}
        topology={topology}
      />
    );
  }

  return <DisabledScheduleButton />;
};
