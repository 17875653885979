import React, { ReactElement } from 'react';
import { ConnectedScheduleButton } from './components/ScheduleButton/container';
import { ConnectedPushModal } from './components/PushModal/container';
import { ScheduleModalContainer } from './components/ScheduleModal/container';

export const Schedule = (): ReactElement => {
  return (
    <>
      <ConnectedScheduleButton />
      <ConnectedPushModal />
      <ScheduleModalContainer />
    </>
  );
};
