import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { generalFieldsSchema } from '../../../../../../validation';
import {
  ControlledTextarea,
  ControlledTextfield,
} from '../../../../../../components/Form';
import { PrimaryButton } from '../../../../../../components/Buttons/PrimaryButton';
import { useLeaveFormPrompt } from '../../../../hooks/useLeaveFormPrompt';
import { FormUnsavedChangesPrompt } from '../../../../../../components/FormUnsavedChangesPrompt';
import { GeneralFormProps } from './container';
import { determineIsButtonDisabled } from './utils/utils';

export function GeneralForm({
  defaultValues,
  onHandleSubmit,
  updating,
}: GeneralFormProps): ReactElement {
  const { t } = useTranslation();
  const { handleSubmit, errors, control, formState } =
    useForm<TopologyGeneralFields>({
      defaultValues: {
        description: defaultValues?.description || '',
        name: defaultValues?.name || '',
        notes: defaultValues?.notes || '',
      },
      mode: 'all',
      resolver: yupResolver(generalFieldsSchema),
    });

  const { isDirty, isValid } = formState;

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  const onSubmit = handleSubmit((data) => {
    onHandleSubmit(data);
  });

  const isButtonDisabled = determineIsButtonDisabled({ isDirty, isValid });

  return (
    <form onSubmit={onSubmit}>
      <ControlledTextfield<TopologyGeneralFields>
        control={control}
        error={errors?.name?.message}
        label={t('general.form.name.label')}
        labelClass="col-3"
        name="name"
        required={true}
      />
      <ControlledTextarea<TopologyGeneralFields>
        control={control}
        error={errors?.description?.message}
        label={t('general.form.description.label')}
        labelClass="col-3"
        name="description"
        testId="general-form-description"
        rows={4}
        required={true}
      />
      <ControlledTextarea<TopologyGeneralFields>
        control={control}
        label={t('general.form.notes.label')}
        labelClass="col-3"
        name="notes"
        rows={4}
      />
      <PrimaryButton
        disabled={isButtonDisabled}
        className="pull-right dbl-margin-top"
        testId="general-form-submit"
        loading={updating}
        type="submit"
        colour="success"
      >
        {t('buttons.save')}
      </PrimaryButton>
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </form>
  );
}
