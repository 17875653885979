import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUpdateFormDirtyStatus } from './useUpdateFormDirtyStatus.tsx';

type UseLeaveFormPromptReturn = {
  showModal: boolean;
  handleCancel: VoidFunction;
  handleConfirm: VoidFunction;
};

export function useLeaveFormPrompt(
  isDirty: boolean,
  isClosingPage?: boolean,
  onCustomConfirm?: VoidFunction,
  onCustomCancel?: VoidFunction,
): UseLeaveFormPromptReturn {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pathnameTo, setPathnameTo] = useState<string | undefined>();
  const [userAccepted, setUserAccepted] = useState<boolean>(false);

  const history = useHistory();

  useUpdateFormDirtyStatus({ isDirty });

  const handleConfirm = () => {
    setUserAccepted(true);
    setShowModal(false);
  };

  const handleCancel = () => {
    setUserAccepted(false);
    setShowModal(false);
    setPathnameTo(undefined);
    onCustomCancel && onCustomCancel();
  };

  useEffect(() => {
    if (userAccepted && pathnameTo) {
      history.push(pathnameTo);
    }
    const unblock = history.block(({ pathname }) => {
      if (isDirty) {
        if (userAccepted) {
          history.push(pathnameTo || pathname);
        } else {
          setPathnameTo(pathname);
          setShowModal(true);
          return false;
        }
      }
    });

    return () => unblock && unblock();
  }, [history, isDirty, pathnameTo, userAccepted]);

  useEffect(() => {
    if (isClosingPage && isDirty && onCustomConfirm && !showModal) {
      if (userAccepted) {
        onCustomConfirm();
      } else {
        setShowModal(true);
      }
    }
  }, [
    isClosingPage,
    isDirty,
    userAccepted,
    showModal,
    onCustomConfirm,
    setShowModal,
  ]);
  return {
    handleCancel,
    handleConfirm,
    showModal,
  };
}
