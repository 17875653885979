import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetRemoteAccessItemQuery,
  useGetRemoteAccessQuery,
  useUpdateRemoteAccessMutation,
} from '../../../../redux/configuration/remote-access/api';
import { RemoteAccessLoader } from './RemoteAccessLoader';
import { RemoteAccess } from '.';

export function RemoteAccessContainer(): ReactElement {
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const { data, isFetching, isLoading, isError } =
    useGetRemoteAccessQuery(topologyUid);
  const {
    isFetching: isFetchingRemoteAccessItem,
    isLoading: isLoadingRemoteAccessItem,
  } = useGetRemoteAccessItemQuery(data?.uid || '', {
    skip: data?.uid === undefined,
  });

  const [updateRemoteAccess, { isLoading: isUpdating }] =
    useUpdateRemoteAccessMutation();

  if (!data) {
    return <RemoteAccessLoader />;
  }

  return (
    <RemoteAccess
      data={data}
      isError={isError}
      isFetching={
        isFetching ||
        isLoading ||
        isFetchingRemoteAccessItem ||
        isLoadingRemoteAccessItem
      }
      isUpdating={isUpdating}
      updateRemoteAccess={updateRemoteAccess}
    />
  );
}

interface RemoteAccessRTKProps {
  data?: RemoteAccess;
  isFetching: boolean;
  isError: boolean;
  isUpdating: boolean;
  updateRemoteAccess: RTKMutation<RemoteAccessPayload, RemoteAccess>;
}

export type RemoteAccessProps = RemoteAccessRTKProps;
