import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../../redux/store';
import {
  clearIsPushModalVisible,
  topologiesSelectors,
} from '../../../../../../../redux/topologies/slice';
import {
  usePostDemoMutation,
  usePushToCustomContentMutation,
} from '../../../../../../../redux/configuration/demos/api';
import { topologiesApi } from '../../../../../../../redux/topologies/api';
import { getIsFormDirty } from '../../../../../../../redux/configuration/isFormDirty/slice';
import { PushModal } from '.';

const mapDispatchToProps = {
  clearIsPushModalVisible,
};

const mapStateToProps = (state: RootState) => {
  const topology = topologiesSelectors.getTopologyToEdit(state);

  return {
    isFormDirty: getIsFormDirty(state),
    isPushModalVisible: topologiesSelectors.getIsPushModalVisible(state),
    topology,
  };
};

const PushModalContainer = ({
  topology,
  isPushModalVisible,
  clearIsPushModalVisible,
  isFormDirty,
}: PushModalContainerProps): ReactElement => {
  const [postTopology, { isLoading: isPostingUpdates }] = usePostDemoMutation({
    fixedCacheKey: 'shared-demo-post',
  });

  const [pushToCustomContent, { isLoading: isPushingUpdates }] =
    usePushToCustomContentMutation({
      fixedCacheKey: 'shared-demo-put',
    });

  const { isLoading: isLoadingTopology, isFetching: isFetchingTopology } =
    topologiesApi.endpoints.getTopology.useQueryState(topology?.uid || '');

  return (
    <PushModal
      clearIsPushModalVisible={clearIsPushModalVisible}
      topology={topology}
      isPushModalVisible={isPushModalVisible}
      postTopology={postTopology}
      pushToCustomContent={pushToCustomContent}
      isLoading={
        isPostingUpdates ||
        isPushingUpdates ||
        isLoadingTopology ||
        isFetchingTopology
      }
      isFormDirty={isFormDirty}
    />
  );
};

type PushModalContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

type RtkPushModalProps = {
  isLoading: boolean;
  postTopology: RTKMutation<DemosPostPayload, DemosPostResponse>;
  pushToCustomContent: RTKMutation<DemosUpdatePayload, DemosPostResponse>;
};

export type PushModalProps = PushModalContainerProps & RtkPushModalProps;

export const ConnectedPushModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushModalContainer);
