import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { templateManagerSelectors } from '../../../../../../redux/templateManager/slice';
import { useGetVmTemplateQuery } from '../../../../../../redux/templateManager/VmTemplates/api';
import { ConnectedTemplateVMEdit } from '../EditTemplateForm/container';
import { EditLoader } from '../../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/EditLoader';
import { useGetEvcModesQuery } from '../../../../../../redux/evcModes/api';
import { buildEvcModeOptions } from '../../../../../utils/evcModeUtils';

function EditVmTemplateContainer({
  selectedInventoryItem,
}: EditVmTemplateModalRTKProps): ReactElement {
  const { data: vmTemplateToEdit, isLoading } = useGetVmTemplateQuery(
    selectedInventoryItem!.uid,
  );
  const {
    evcModes,
    data: evcModesData,
    isLoading: isLoadingEvcModes,
  } = useGetEvcModesQuery(undefined, {
    selectFromResult: ({ data, isError, isLoading }) => ({
      data,
      evcModes: buildEvcModeOptions(data?.evcModes || []),
      isError,
      isLoading,
    }),
  });

  if (
    isLoading ||
    isLoadingEvcModes ||
    !evcModesData ||
    !evcModes ||
    !selectedInventoryItem ||
    !vmTemplateToEdit
  ) {
    return <EditLoader />;
  }

  return (
    <ConnectedTemplateVMEdit
      inventoryVmUid={
        selectedInventoryItem.vm ? selectedInventoryItem.vm.uid : ''
      }
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  selectedInventoryItem:
    templateManagerSelectors.getSelectedInventoryItem(state),
});

export type EditVmTemplateModalRTKProps = ReturnType<typeof mapStateToProps>;

export const ConnectedEditVMTemplateForm = connect(mapStateToProps)(
  EditVmTemplateContainer,
);
