import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../../../../components/PageHeading';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { startStopOrderSelectors } from '../../../../../redux/configuration/start-stop-order/slice';
import {
  useGetVmStopOrderItemQuery,
  useGetVmStopOrderQuery,
  useUpdateVmStopOrderMutation,
} from '../../../../../redux/configuration/start-stop-order/vm-stop/api';
import { StartStopOrderContent } from '../components/StartStopOrderContent';
import { VM_STOP_HELP_LINKS } from '../../../../../constants/user-guide';
import { useLeaveFormPrompt } from '../../../hooks/useLeaveFormPrompt';
import { FormUnsavedChangesPrompt } from '../../../../../components/FormUnsavedChangesPrompt';
import { HelpButton } from '../../../../../components/HelpButton';
import { VMStopTable } from './components/VMStopTable';

export function VMStop(): ReactElement {
  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();

  const { data, isFetching, isError, isLoading, refetch } =
    useGetVmStopOrderQuery(topologyUid);

  const {
    isFetching: isFetchingItem,
    isError: isErrorFetchingItem,
    isLoading: isLoadingItem,
  } = useGetVmStopOrderItemQuery(data?.uid || '', {
    skip: data?.uid === undefined,
  });

  const [updateVMStopOrder, { isLoading: isUpdating }] =
    useUpdateVmStopOrderMutation();

  const isDirty = useSelector(startStopOrderSelectors.getStartStopOrderIsDirty);
  const startStopOrder = useSelector(startStopOrderSelectors.getStartStopOrder);

  const { showModal, handleConfirm, handleCancel } =
    useLeaveFormPrompt(isDirty);

  return (
    <section className="half-padding-left">
      <PageHeading
        ctaBtn={
          <PrimaryButton
            className="pull-right dbl-margin-top"
            testId="vm-stop-order-submit"
            type="submit"
            colour="success"
            onClick={() => updateVMStopOrder(startStopOrder!)}
            disabled={!isDirty}
            loading={isUpdating}
          >
            {t('buttons.update')}
          </PrimaryButton>
        }
        pageTitle={t('startStopOrder.vmStop.name')}
      />
      {!isError && !isErrorFetchingItem && startStopOrder && (
        <StartStopOrderContent
          isFetching={isFetching || isFetchingItem}
          buttonUnorderedLabel={t('buttons.unordered')}
          buttonOrderedLabel={t('buttons.ordered')}
          ordered={startStopOrder.ordered}
          positions={startStopOrder.positions}
          alertText={t('startStopOrder.vmStop.table.noResults')}
          table={VMStopTable}
          refetch={refetch}
          isLoading={isLoading || isLoadingItem}
        />
      )}
      <FormUnsavedChangesPrompt
        showModal={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <HelpButton links={VM_STOP_HELP_LINKS} />
    </section>
  );
}
