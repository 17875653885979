export type HandleCloneTopology = {
  topology: Topology | undefined;
  onClone: TopologyCloneAction;
};

export const handleCloneTopology = ({
  topology,
  onClone,
}: HandleCloneTopology): void => {
  if (!topology || !topology._links.clones) {
    return undefined;
  }

  onClone({ isConfigurationPage: true, topology });
};
