import { dcloudApi } from '../../../api';
import { INBOUND_PROXY_RULES, TOPOLOGIES_PATH } from '../../../../api/paths';
import i18n from '../../../../i18n';
import { TAGS } from '../../../api/constants';

export const inboundProxyRulesApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createInboundProxyRule: build.mutation<
      InboundProxyRule,
      InboundProxyRulePostPayload
    >({
      invalidatesTags: [
        TAGS.INBOUND_PROXY_RULES,
        TAGS.VIRTUAL_MACHINE_TARGETS,
        TAGS.TOPOLOGY,
      ],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('traffic.inboundProxyRules.add.error'),
        method: 'post',
        successMessage: i18n.t('traffic.inboundProxyRules.add.success'),
        url: INBOUND_PROXY_RULES,
      }),
    }),
    deleteInboundProxyRule: build.mutation<undefined, InboundProxyRule>({
      invalidatesTags: [
        TAGS.INBOUND_PROXY_RULES,
        TAGS.VIRTUAL_MACHINE_TARGETS,
        TAGS.TOPOLOGY,
      ],
      query: ({ uid }) => ({
        errorMessage: i18n.t('traffic.inboundProxyRules.delete.error'),
        method: 'delete',
        successMessage: i18n.t('traffic.inboundProxyRules.delete.success'),
        url: `${INBOUND_PROXY_RULES}/${uid}`,
      }),
    }),
    getInboundProxyRules: build.query<InboundProxyRulesResponse, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.INBOUND_PROXY_RULES],
      query: (topologyUid) => ({
        errorMessage: i18n.t('traffic.inboundProxyRules.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INBOUND_PROXY_RULES}`,
      }),
    }),
  }),
});

export const {
  useCreateInboundProxyRuleMutation,
  useDeleteInboundProxyRuleMutation,
  useGetInboundProxyRulesQuery,
} = inboundProxyRulesApi;
