import React from 'react';
import { connect } from 'react-redux';
import {
  usePostDemoMutation,
  usePushToCustomContentMutation,
} from '../../../../redux/configuration/demos/api';
import { RootState } from '../../../../redux/store';
import {
  clearTopologyToSave,
  clearTopologyToUpdate,
  topologiesSelectors,
} from '../../../../redux/topologies/slice';
import { getIsFormDirty } from '../../../../redux/configuration/isFormDirty/slice';
import { SaveCustomContentModal } from '.';

export type PropsFromRedux = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = (state: RootState) => ({
  isFormDirty: getIsFormDirty(state),
  topologyToSave: topologiesSelectors.getTopologyToSave(state),
  topologyToUpdate: topologiesSelectors.getTopologyToUpdate(state),
});

const mapDispatchToProps = {
  clearTopologyToSave,
  clearTopologyToUpdate,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ConnectedSaveCustomContentModal: any = connect(
  mapStateToProps,
  mapDispatchToProps,
)(function (props) {
  const [postTopology, { isLoading }] = usePostDemoMutation({
    fixedCacheKey: 'shared-demo-post',
  });
  const [pushToCustomContent, { isLoading: isUpdating }] =
    usePushToCustomContentMutation({
      fixedCacheKey: 'shared-demo-put',
    });

  return (
    <SaveCustomContentModal
      {...props}
      saveTopology={postTopology}
      updateDemo={pushToCustomContent}
      isSaving={isLoading || isUpdating}
    />
  );
});
