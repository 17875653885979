import {
  REMOTE_ACCESSES_PATH,
  REMOTE_ACCESS_PATH,
  TOPOLOGIES_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { dcloudApi } from '../../api';
import { TAGS } from '../../api/constants';

export const remoteAccessApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getRemoteAccess: build.query<RemoteAccess, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.REMOTE_ACCESS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('remoteAccess.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${REMOTE_ACCESS_PATH}`,
      }),
    }),
    getRemoteAccessItem: build.query<string, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.REMOTE_ACCESS_ITEM],
      query: (remoteAccessUid) => ({
        errorMessage: i18n.t('remoteAccess.fetch.error'),
        method: 'get',
        url: `${REMOTE_ACCESS_PATH}/${remoteAccessUid}`,
      }),
    }),
    updateRemoteAccess: build.mutation<RemoteAccess, RemoteAccessPayload>({
      invalidatesTags: [
        TAGS.REMOTE_ACCESS,
        TAGS.REMOTE_ACCESS_ITEM,
        TAGS.TOPOLOGY,
      ],
      query: (remoteAccess) => ({
        data: remoteAccess,
        errorMessage: i18n.t('remoteAccess.update.error'),
        method: 'PUT',
        successMessage: i18n.t('remoteAccess.update.success'),
        url: `${REMOTE_ACCESSES_PATH}/${remoteAccess.uid}`,
      }),
    }),
  }),
});

export const {
  useGetRemoteAccessQuery,
  useGetRemoteAccessItemQuery,
  useUpdateRemoteAccessMutation,
} = remoteAccessApi;
