import React, { ReactElement } from 'react';
import styles from './TopologyTitle.module.css';

export type TopologyTitleProps = {
  title: string;
};

export function TopologyTitle({ title }: TopologyTitleProps): ReactElement {
  return (
    <div className={styles.topologyTitle} title={title}>
      {title}
    </div>
  );
}
