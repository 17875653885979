import { push } from 'connected-react-router';
import { dcloudApi } from '../api';
import i18n from '../../i18n';
import { TOPOLOGIES_PATH, CLONES } from '../../api/paths';
import { MainRoutes } from '../../constants/routes';
import { setTopologies } from '../topologiesFilters/slice';
import { TAGS } from '../api/constants';
import {
  clearTopologyToClone,
  clearTopologyToDelete,
  setClonedTopologyUid,
  setTopologyToEdit,
} from './slice';
import { sortTopologies } from './utils';

export const topologiesApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    cloneTopology: build.mutation<Topology, CloneTopologyPostPayload>({
      invalidatesTags: [TAGS.TOPOLOGIES],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (payload.isConfigurationPage) {
            dispatch(setClonedTopologyUid(data.uid));
          } else {
            dispatch(push(MainRoutes.Edit.replace(':uid', data.uid)));
          }
        } catch (err) {
          return;
        } finally {
          dispatch(clearTopologyToClone());
        }
      },
      query: ({ payload, uid }) => ({
        data: payload,
        errorMessage: i18n.t('topologies.clone.error'),
        method: 'post',
        successMessage: i18n.t('topologies.clone.success'),
        url: `${TOPOLOGIES_PATH}/${uid}/${CLONES}`,
      }),
    }),
    createTopologyApi: build.mutation<Topology, TopologyToCreate>({
      invalidatesTags: [TAGS.TOPOLOGIES, TAGS.TOPOLOGY],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(push(MainRoutes.Edit.replace(':uid', data.uid)));
        } catch (err) {
          return;
        }
      },
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('createTopology.error'),
        method: 'post',
        successMessage: i18n.t('createTopology.success'),
        url: `${TOPOLOGIES_PATH}`,
      }),
    }),
    createTopologyFromTemplate: build.mutation<Topology, TopologyToCreate>({
      invalidatesTags: [TAGS.TOPOLOGIES],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(push(MainRoutes.Edit.replace(':uid', data.uid)));
        } catch (err) {
          return;
        }
      },
      query: (payload) => ({
        data: { description: payload.description, name: payload.name },
        errorMessage: i18n.t('createTopology.error'),
        method: 'post',
        successMessage: i18n.t('createTopology.success'),
        url: `${TOPOLOGIES_PATH}/${payload.topologyUid}/${CLONES}`,
      }),
    }),
    deleteTopology: build.mutation<undefined, string>({
      invalidatesTags: [TAGS.TOPOLOGIES],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(clearTopologyToDelete());
        } catch (err) {
          throw err;
        } finally {
          dispatch(clearTopologyToDelete());
        }
      },
      query: (topologyUid) => ({
        errorMessage: i18n.t('topologies.delete.error'),
        method: 'delete',
        successMessage: i18n.t('topologies.delete.success'),
        url: `${TOPOLOGIES_PATH}/${topologyUid}`,
      }),
    }),
    getTopologies: build.query<Topology[], undefined>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTopologies(data));
        } catch (err) {
          return;
        }
      },
      providesTags: [TAGS.TOPOLOGIES],
      query: () => ({
        errorMessage: i18n.t('topologies.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}`,
      }),
      transformResponse: sortTopologies,
    }),
    getTopology: build.query<Topology, string>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTopologyToEdit(data));
        } catch (err) {
          return;
        }
      },
      providesTags: [TAGS.TOPOLOGY],
      query: (uid) => ({
        errorMessage: i18n.t('general.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${uid}`,
      }),
    }),
    updateTopology: build.mutation<Topology, TopologyToUpdate>({
      invalidatesTags: [TAGS.TOPOLOGY],
      query: (topology) => ({
        data: topology,
        errorMessage: i18n.t('general.update.error'),
        method: 'put',
        successMessage: i18n.t('general.update.success'),
        url: `${TOPOLOGIES_PATH}/${topology.uid}`,
      }),
    }),
  }),
});

export const {
  useCreateTopologyFromTemplateMutation,
  useDeleteTopologyMutation,
  useGetTopologiesQuery,
  useCloneTopologyMutation,
  useUpdateTopologyMutation,
  useGetTopologyQuery,
  useCreateTopologyApiMutation,
} = topologiesApi;
