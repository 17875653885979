import { dcloudApi } from '../../api';
import i18n from '../../../i18n';
import {
  HARDWARE_PATH,
  INVENTORY_HARDWARE_PATH,
  INVENTORY_HARDWARE_SCRIPTS,
  INVENTORY_HARDWARE_TEMPLATES,
  TOPOLOGIES_PATH,
} from '../../../api/paths';
import { diagramApi } from '../../diagram/api';
import { TAGS } from '../../api/constants';
import { getSortedHardwareScripts } from './utils';

export const hardwareApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    addHardwareItem: build.mutation<Hardware, InventoryHardwareItemToAdd>({
      invalidatesTags: [
        TAGS.HARDWARE_ITEMS,
        TAGS.TOPOLOGY_LIMITS,
        TAGS.TOPOLOGY,
      ],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('hardware.add.error'),
        method: 'POST',
        successMessage: i18n.t('hardware.add.success'),
        url: `${HARDWARE_PATH}`,
      }),
    }),
    deleteHardware: build.mutation<undefined, string>({
      invalidatesTags: [
        TAGS.HARDWARE_ITEMS,
        TAGS.TOPOLOGY_LIMITS,
        TAGS.TOPOLOGY,
      ],
      query: (uid) => ({
        errorMessage: i18n.t('hardware.delete.error'),
        method: 'DELETE',
        successMessage: i18n.t('hardware.delete.success'),
        url: `${HARDWARE_PATH}/${uid}`,
      }),
    }),
    fetchAllHardware: build.query<Hardware[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.HARDWARE_ITEMS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('hardware.fetch.error'),
        method: 'GET',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${HARDWARE_PATH}`,
      }),
      transformResponse: ({ hardwareItems }: HardwareQueryResponse) =>
        hardwareItems,
    }),
    fetchInventoryHardwareItems: build.query<
      InventoryHardwareItemsResponse,
      string
    >({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.HARDWARE_INVENTORY_SCRIPTS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('hardware.get.error'),
        method: 'GET',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_HARDWARE_PATH}`,
      }),
    }),
    getHardwareItem: build.query<Hardware, string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.HARDWARE_ITEM],
      query: (hardwareUid) => ({
        errorMessage: i18n.t('hardware.get.error'),
        method: 'GET',
        url: `${HARDWARE_PATH}/${hardwareUid}`,
      }),
    }),
    getHardwareTemplates: build.query<HardwareScript[], string>({
      providesTags: [TAGS.HARDWARE_INVENTORY_SCRIPTS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('hardware.edit.error.fetchScripts'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_HARDWARE_TEMPLATES}`,
      }),
      transformResponse: getSortedHardwareScripts,
    }),
    getInventoryScripts: build.query<HardwareScript[], string>({
      providesTags: [TAGS.HARDWARE_INVENTORY_SCRIPTS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('hardware.edit.error.fetchScripts'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_HARDWARE_SCRIPTS}`,
      }),
      transformResponse: getSortedHardwareScripts,
    }),
    updateHardwareItem: build.mutation<Hardware, UpdateHardwarePayload>({
      invalidatesTags: [
        TAGS.HARDWARE_ITEM,
        TAGS.TOPOLOGY_LIMITS,
        TAGS.TOPOLOGY,
      ],
      async onQueryStarted({ isModal }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if (isModal) {
            dispatch(diagramApi.util.invalidateTags([TAGS.DIAGRAM]));
          }
        } catch (err) {}
      },
      query: (hardware) => ({
        data: hardware,
        errorMessage: i18n.t('hardware.edit.error.update'),
        method: 'PUT',
        successMessage: i18n.t('hardware.edit.success'),
        url: `${HARDWARE_PATH}/${hardware.uid}`,
      }),
    }),
  }),
});

export const {
  useGetInventoryScriptsQuery,
  useFetchAllHardwareQuery,
  useAddHardwareItemMutation,
  useDeleteHardwareMutation,
  useGetHardwareItemQuery,
  useGetHardwareTemplatesQuery,
  useFetchInventoryHardwareItemsQuery,
  useUpdateHardwareItemMutation,
} = hardwareApi;
