import React, { ReactElement } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from '../../index.module.css';
import { determineEnabledScheduleButtonOnConfirm } from './utils/utils';

export type EnabledScheduleButtonProps = {
  setIsScheduleModalVisible: ActionCreatorWithPayload<Topology, string>;
  setIsPushModalVisible: ActionCreatorWithPayload<Topology, string>;
  topology: Topology;
};

export const EnabledScheduleButton = ({
  setIsScheduleModalVisible,
  setIsPushModalVisible,
  topology,
}: EnabledScheduleButtonProps): ReactElement => {
  const { t } = useTranslation();

  const colourClasses = classNames(
    { [styles.inSync]: topology.inSyncWithDcloud === true },
    { [styles.notInSync]: topology.inSyncWithDcloud === false },
  );

  const handleOnConfirm = () => {
    determineEnabledScheduleButtonOnConfirm({
      setIsPushModalVisible,
      setIsScheduleModalVisible,
      topology,
    });
  };

  return (
    <button
      type="button"
      onClick={handleOnConfirm}
      className={classNames(styles.scheduleButton, colourClasses)}
    >
      {t('buttons.schedule')}
    </button>
  );
};
