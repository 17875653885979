import { dcloudApi } from '../../api';
import {
  TOPOLOGIES_PATH,
  NETWORKS_PATH,
  INVENTORY_NETWORKS_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import { TAGS } from '../../api/constants';
import { setNetworkToDelete, setNetworkToEdit, setShowAddModal } from './slice';

export const networksApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createNetwork: build.mutation<Network, NetworkCreatePayload>({
      invalidatesTags: [TAGS.NETWORKS, TAGS.DIAGRAM, TAGS.TOPOLOGY],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          throw err;
        } finally {
          dispatch(setShowAddModal(false));
        }
      },
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('networks.form.add.error'),
        method: 'post',
        successMessage: i18n.t('networks.form.add.success'),
        url: `${NETWORKS_PATH}`,
      }),
    }),
    deleteNetwork: build.mutation<undefined, Network>({
      invalidatesTags: [TAGS.NETWORKS, TAGS.NETWORK, TAGS.TOPOLOGY],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
        } finally {
          dispatch(setNetworkToDelete(undefined));
        }
      },
      query: ({ uid: networkUid }) => ({
        errorMessage: i18n.t('networks.delete.error'),
        method: 'delete',
        successMessage: i18n.t('networks.delete.success'),
        url: `${NETWORKS_PATH}/${networkUid}`,
      }),
    }),
    getInventoryNetworks: build.query<InventoryNetwork[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.INVENTORY_NETWORKS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('inventoryNetworks.fetch.error'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${INVENTORY_NETWORKS_PATH}`,
      }),
      transformResponse: (response: GetInventoryNetworksResponse) =>
        response.inventoryNetworks,
    }),
    getNetwork: build.query<Network, string>({
      providesTags: [TAGS.NETWORK],
      query: (networkUid) => ({
        errorMessage: i18n.t('networks.fetch.errors.one'),
        method: 'get',
        url: `${NETWORKS_PATH}/${networkUid}`,
      }),
    }),
    getNetworks: build.query<Network[], string>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.NETWORKS],
      query: (topologyUid) => ({
        errorMessage: i18n.t('networks.fetch.errors.many'),
        method: 'get',
        url: `${TOPOLOGIES_PATH}/${topologyUid}${NETWORKS_PATH}`,
      }),
      transformResponse: (response: GetNetworksResponse) => response.networks,
    }),
    updateNetwork: build.mutation<Network, NetworkToUpdate>({
      invalidatesTags: [TAGS.NETWORKS, TAGS.NETWORK, TAGS.TOPOLOGY],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          throw err;
        } finally {
          dispatch(setNetworkToEdit(undefined));
        }
      },
      query: (network) => ({
        data: network,
        errorMessage: i18n.t('networks.update.error'),
        method: 'put',
        successMessage: i18n.t('networks.update.success'),
        url: `${NETWORKS_PATH}/${network.uid}`,
      }),
    }),
  }),
});

export const {
  useGetNetworkQuery,
  useGetNetworksQuery,
  useLazyGetNetworksQuery,
  useCreateNetworkMutation,
  useDeleteNetworkMutation,
  useUpdateNetworkMutation,
  useGetInventoryNetworksQuery,
  useLazyGetInventoryNetworksQuery,
} = networksApi;
