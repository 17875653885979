import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from 'dcloud-shared-ui';
import styles from './index.module.css';

export interface HeaderProps {
  center?: ReactNode;
  title: string;
  left?: ReactNode;
  logoLinkTo?: string;
  right?: ReactNode;
  autoHideLink?: boolean;
}

export function Header({
  center,
  title,
  logoLinkTo,
  right,
  left,
  autoHideLink,
}: HeaderProps): ReactElement {
  return (
    <header className={classnames('header', styles.root)}>
      <div className="header-panels dbl-padding-left dbl-padding-right">
        <div
          className={classnames('header-panel', {
            [styles.disabledLink]: !logoLinkTo,
          })}
        >
          <Link
            data-testid="header-logo-link"
            to={logoLinkTo || '#'}
            className="header__logo"
          >
            <Icon name="cisco" />
          </Link>
          <div className={classnames('header__title', styles.title)}>
            <span className={autoHideLink ? styles.linkWrapper : ''}>
              <Link to={logoLinkTo || '#'} className={styles.link}>
                {title}
              </Link>
            </span>
            <span className={styles.topologyNameWrapper}>
              <div className={classnames('header-panel header-panel--left')}>
                {left}
              </div>
            </span>
          </div>
        </div>
        {center && <div className={styles.center}>{center}</div>}
        {right && (
          <div className="header-panel header-panel--right">{right}</div>
        )}
      </div>
    </header>
  );
}
