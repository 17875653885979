import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { evcModesApi } from '../../../../../../../../redux/evcModes/api';
import { buildEvcModeOptions } from '../../../../../../../utils/evcModeUtils';
import { RootState } from '../../../../../../../../redux/store';
import { AdvancedSettingsForm } from '.';

function AdvancedSettingsFormContainer({
  hideDiagramFields,
}: AdvancedSettingsFormProps): ReactElement {
  const { evcModes } = evcModesApi.endpoints.getEvcModes.useQueryState(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        evcModes: buildEvcModeOptions(data?.evcModes || []),
      }),
    },
  );

  return (
    <AdvancedSettingsForm
      hideDiagramFields={hideDiagramFields}
      evcModes={evcModes}
    />
  );
}

type AdvancedSettingsFormOwnProps = {
  hideDiagramFields?: boolean;
};

const mapStateToProps = (
  state: RootState,
  ownProps: AdvancedSettingsFormOwnProps,
) => ({
  hideDiagramFields: ownProps.hideDiagramFields,
});

type AdvancedSettingsFormProps = ReturnType<typeof mapStateToProps>;

export const ConnectedAdvancedSettingsForm = connect(mapStateToProps)(
  AdvancedSettingsFormContainer,
);
