import React, { KeyboardEvent, ReactElement, useRef } from 'react';
import classnames from 'classnames';
import { Handle, useDiagramContext } from '..';
import { Node } from '../Node';
import { onNodeElementKeyUp } from '../utils/utils';
import { usePosition } from './hooks/use-position';
import styles from './FourHandleNode.module.css';
import { useDrag } from './hooks/use-drag';
import { useResize } from './hooks/use-resize';
import { FourHandleNodeProps } from './container';
import { generateClassNamesForBoxStyling } from './utils/boxUtil';
import { useFocusBox } from './hooks/useFocusBox/use-focus-box';

export function FourHandleNode({
  className,
  display,
  isReadOnly,
  uid,
  deleteNode,
  isSnapToGridEnabled = true,
}: FourHandleNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { toggleSelectedItem, selectedItem } = useDiagramContext();
  const isSelected = !isReadOnly && selectedItem === uid;

  const hookProps = {
    display,
    isSelected,
    nodeRef,
    uid,
  };

  useFocusBox({ isSelected, nodeRef });
  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });
  useResize({ ...hookProps, isReadOnly });

  return (
    <Node
      isBoxNode={true}
      onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
        onNodeElementKeyUp({
          deleteNode,
          element: nodeRef?.current,
          isReadOnly,
          isSelected,
          keyName: event.key,
          toggleSelectedItem,
          uid,
        });
      }}
      uid={uid}
      ref={nodeRef}
      className={classnames(styles.root, className, {
        [styles.selected]: isSelected,
        [styles.noHover]: isReadOnly,
        ...generateClassNamesForBoxStyling(display),
      })}
      isDisabled={false}
      isReadOnly={isReadOnly}
      isSelected={isSelected}
      onClick={() => toggleSelectedItem(uid)}
      data-component={uid}
      isRemovable={true}
      isDrawingType={true}
      isConnectionAllowed={false}
    >
      <span
        className={classnames(styles.handles, {
          [styles.disabledHandles]: !isSelected,
        })}
      >
        <Handle
          className={classnames(styles.handle, styles.topLeft)}
          type="topLeft"
        />
        <Handle
          className={classnames(styles.handle, styles.topRight)}
          type="topRight"
        />
        <Handle
          className={classnames(styles.handle, styles.bottomLeft)}
          type="bottomLeft"
        />
        <Handle
          className={classnames(styles.handle, styles.bottomRight)}
          type="bottomRight"
        />
      </span>
    </Node>
  );
}
