import { dcloudApi } from '../../api';
import {
  DEMOS_PATH,
  INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH,
  DATACENTERS_PATH,
} from '../../../api/paths';
import i18n from '../../../i18n';
import {
  clearTopologyToSave,
  clearTopologyToUpdate,
} from '../../topologies/slice';
import { TAGS, TAGS_INVALIDATED_ON_PUSH } from '../../api/constants';

export const demosApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getDemosByDC: build.query<FetchInventoryDemosByDCResponse, DataCenter>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.DEMOS_BY_DC],
      query: (dcUid) => ({
        errorMessage: i18n.t('demos.fetch.errors.many'),
        method: 'get',
        url: `${DATACENTERS_PATH}/${dcUid.toLowerCase()}${INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH}`,
      }),
    }),
    postDemo: build.mutation<DemosPostResponse, DemosPostPayload>({
      invalidatesTags: [...TAGS_INVALIDATED_ON_PUSH],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
        } finally {
          dispatch(clearTopologyToSave());
        }
      },
      query: (payload) => ({
        data: { topology: { uid: payload } },
        errorMessage: i18n.t('topologies.push.error'),
        method: 'post',
        successMessage: i18n.t('topologies.push.success'),
        url: DEMOS_PATH,
      }),
    }),
    pushToCustomContent: build.mutation<DemosPostResponse, DemosUpdatePayload>({
      invalidatesTags: [...TAGS_INVALIDATED_ON_PUSH],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
        } finally {
          dispatch(clearTopologyToUpdate());
        }
      },
      query: (demo) => ({
        data: demo,
        errorMessage: i18n.t('topologies.push.error'),
        method: 'PUT',
        successMessage: i18n.t('topologies.push.success'),
        url: `${DEMOS_PATH}/${demo.id}`,
      }),
    }),
  }),
});

export const {
  useGetDemosByDCQuery,
  usePostDemoMutation,
  usePushToCustomContentMutation,
} = demosApi;
