import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../../../../../i18n';
import { MainRoutes } from '../../../../../../constants/routes';
import styles from './index.module.css';

export type ClonedTopologyLinkBodyProps = {
  clonedTopologyUid: string;
  clearClonedTopologyUid: VoidFunction;
};

export const ClonedTopologyLinkBody = ({
  clonedTopologyUid,
  clearClonedTopologyUid,
}: ClonedTopologyLinkBodyProps): ReactElement => {
  const CLONED_TOPOLOGY_LINK_LABEL = i18n.t(
    'topologies.clone.clonedTopologyLinkLabel',
  );

  const handleOnClick = () => {
    clearClonedTopologyUid();
  };

  return (
    <div className={styles.clonedTopologyLinkWrapper}>
      <Link
        to={MainRoutes.Edit.replace(':uid', clonedTopologyUid)}
        onClick={handleOnClick}
      >
        {CLONED_TOPOLOGY_LINK_LABEL}
      </Link>
    </div>
  );
};
