export const CLONES = 'clones';
export const CURRENT_USER = '/current-user';
export const CONNECTIONS_PATH = '/connections';
export const DATACENTERS_PATH = '/datacenters';
export const DEMOS_PATH = '/demos';
export const DEMO_TEMPLATES = '/topology-templates';
export const DIAGRAM_PATH = '/diagram';
export const DOCUMENTS_PATH = '/documents';
export const DOCUMENTATION_PATH = '/documentation';
export const DNS_ASSETS = '/inventory-dns-assets';
export const EXTERNAL_DNS = '/external-dns-records';
export const GLOBAL_PATH = '/global';
export const HARDWARE_PATH = '/hardware-items';
export const HARDWARE_START_ORDER = '/hardware-start-order';
export const INBOUND_PROXY_RULES = '/inbound-proxy-rules';
export const INTEGRATIONS_PATH = '/integrations';
export const INTEGRATION_LICENSE_PATH = '/integration-licenses';
export const TAGS_PATH = '/tags';
export const SAAS_TEMPLATES_PATH = '/saas-templates';
export const SAAS_TEMPLATE_AVAILABILITY_VALUES_PATH =
  '/saas-template-availability-values';
export const INTERNAL_IPS = '/internal-ips';
export const INVENTORIES_PATH = '/inventories';
export const INVENTORY_DEMO_VMS_PATH = '/vms';
export const INVENTORY_HARDWARE_PATH = '/inventory-hardware-items';
export const INVENTORY_HARDWARE_SCRIPTS = '/inventory-hardware-scripts';
export const INVENTORY_HARDWARE_TEMPLATES = '/inventory-hardware-templates';
export const INVENTORY_LICENCES_PATH = '/inventory-licenses';
export const INVENTORY_NETWORKS_PATH = '/inventory-networks';
export const INVENTORY_SAAS_INTEGRATIONS = '/inventory-saas-integrations';
export const INVENTORY_VIRTUAL_MACHINES_DEMOS_PATH = '/inventory-demos';
export const INVENTORY_TEMPLATE_TOPOLOGIES_PATH =
  '/inventory-template-topologies';
export const TELEPHONY_ITEMS_PATH = '/telephony-items';
export const INVENTORY_TELEPHONY_ITEMS_PATH = '/inventory-telephony-items';
export const INVENTORY_VIRTUAL_MACHINES_TEMPLATES_PATH =
  '/inventory-template-vms';
export const LICENCES_PATH = '/licenses';
export const LIMITATIONS_PATH = '/validation-limits';
export const MAIL_SERVERS = '/mail-servers';
export const METADATA_ARCHITECTURES = '/metadata/architectures';
export const NAT_RULES = '/nat-rules';
export const NAT_RULES_IP = '/ip-nat-rules';
export const NAT_RULES_VM = '/vm-nic-nat-rules';
export const NETWORKS_PATH = '/networks';
export const NODES_PATH = '/nodes';
export const OPERATING_SYSTEMS_PATH = '/os-families';
export const RECIPES_PATH = '/inventory/engine/idac/workflows';
export const REMOTE_ACCESS_PATH = '/remote-access';
export const REMOTE_ACCESSES_PATH = '/remote-access';
export const SCENARIOS_PATH = '/scenario';
export const SESSION_HW_ACTIONS_PATH = '/session-hw-actions';
export const SESSION_ACTIONS_PATH = '/session-actions';
export const SESSIONS_PATH = '/sessions';
export const SESSIONS_SAVE_PATH = '/session-save-actions';
export const SESSION_VM_ACTIONS_PATH = '/session-vm-actions';
export const SESSIONS_TXT_RECORDS = '/session-txt-records';
export const SESSION_SHARE = '/session-shares';
export const SRV_PROTOCOLS = '/srv-protocols';
export const TEMPLATED_TOPOLOGIES_PATH = '/templated-topologies';
export const TOPOLOGIES_PATH = '/topologies';
export const VIDEOS_PATH = '/video-showcases';
export const TOPOLOGY_LIMITS_PATH = '/limits';
export const VM_NETWORK_INTERFACE_TYPE = '/vm-network-interface-types';
export const VM_START_ORDER = '/vm-start-order';
export const VM_STATUS = '/vm-status';
export const VM_STOP_ORDER = '/vm-stop-order';
export const VM_TARGETS = '/vm-nic-targets';
export const TEMPLATED_VMS = '/templated-inventory-vms';
export const VM_TEMPLATES = '/vm-templates';
export const VMS_PATH = '/vms';
export const VM_CLONE_PATH = '/vm-clones';
export const ACCESS_ROLE_ASSIGNMENT_PATH = '/access-role-assignments';
export const SAAS_INTEGRATIONS_PATH = '/saas-integrations';
export const EVC_MODES_PATH = '/evc-modes';

export const EXPO_ENGAGEMENTS = '/public/engagements';
