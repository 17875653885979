import React, { ReactElement } from 'react';
import { ConfirmModal } from '../../../../../../Modals';
import i18n from '../../../../../../../i18n';
import { linkToNewTab } from '../../../../../../../utils/helpers';
import { determineConfirmButtonColour } from '../../../../../../../pages/Topologies/components/SaveCustomContentModal/utils/utils';
import { ScheduleModalProps } from './container';
import {
  determineScheduleModalConfirmButtonLabel,
  determineScheduleModalSubHeading,
} from './utils/utils';

export const ScheduleModal = ({
  clearIsScheduleModalVisible,
  topology,
  isScheduleModalVisible,
  isFormDirty,
}: ScheduleModalProps): ReactElement => {
  if (!isScheduleModalVisible || !topology) {
    return <></>;
  }

  const handleOnClose = () => {
    clearIsScheduleModalVisible();
  };

  const handleOnConfirm = () => {
    linkToNewTab(topology._links.schedule.href);
    clearIsScheduleModalVisible();
  };

  return (
    <ConfirmModal
      show={!!topology}
      heading={i18n.t('topologies.schedule.inSync.heading')}
      subHeading={determineScheduleModalSubHeading({ isFormDirty })}
      onCancel={handleOnClose}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      loading={false}
      confirmButtonLabel={determineScheduleModalConfirmButtonLabel({
        isFormDirty,
      })}
      confirmButtonColour={determineConfirmButtonColour({ isFormDirty })}
      cancelButtonLabel={i18n.t('buttons.cancel')}
      clickScreenToClose={true}
      testId="schedule-modal"
    />
  );
};
